import {
  Avatar,
  Card,
  IconButton,
  Menu,
  Paper,
  Typography,
  Box,
  ListItem,
  ListItemIcon,
  ListItemText,
  List,
  Collapse,
  CircularProgress,
} from '@material-ui/core'
import { ArrowDropDown, ChevronRight, Edit, ExpandLess, ExpandMore } from '@material-ui/icons'
import { graphql } from 'babel-plugin-relay/macro'
import ArchiveActions from '../../components/ArchiveActions'
import ArchivedBanner from '../../components/ArchivedBanner'
import Badge from '../../components/Badge'
import Button from '../../components/Button'
import Hero from '../../components/Hero'
import KeyField from '../../components/KeyField'
import React, { useRef, useState, unstable_useTransition, Suspense, useEffect } from 'react'
import RedeemOutlinedIcon from '@material-ui/icons/RedeemOutlined'
import SemanticLine from '../../components/SemanticLine'
import ShipmentWorkflow from '../../components/Workflows/ShipmentWorkflow'
import StickyNote from '../../components/StickyNote'
import styled from 'styled-components'
import SubStage from '../../components/SubStage'
import TabsUI from '../../components/TabsUI/TabsUI'
import useLazyLoadQueryWithSubscription from '../../hooks/useLazyLoadQueryWithSubscription'
import useWorkspaceNavigate from '../../hooks/useWorkspaceNavigate'
import Watchers from '../../components/Watchers'
import WorkspacedLink from '../../components/WorkspacedLink'
import { Skeleton } from '@material-ui/lab'
import { isMedium, isSmall, media } from '../../theme'
import { ShipmentShowHeroCardQuery } from '../../__generated__/ShipmentShowHeroCardQuery.graphql'
import { tabs } from './tabs'
import { Title as ReactHeadTitle } from 'react-head'
import { ShowPageRenderContext } from '../../viewTypes'
import { ShipmentShowHeroCardContainerQuery } from '../../__generated__/ShipmentShowHeroCardContainerQuery.graphql'

interface Props {
  slug: string
  activeTab: string
  onEdit: () => void
  renderContext: ShowPageRenderContext
}

const ContentRoot = styled.div`
  width: 100%;
  min-height: 100px;
`

const NonStageContent = styled.div`
  width: 100%;
  display: grid;
  padding: 0 64px;
  grid-template-columns: 3fr 1fr;

  ${media.medium`
    padding: 0 32px;
    grid-template-columns: 3fr 1fr;
  `}

  ${media.small`
    grid-template-columns: 1fr;
    width: 100%;
    padding-left: 8px;
    padding-right: 8px;
  `}
`

const Titlebar = styled(Paper)`
  position: relative;
  width: 100%;
  height: 72px;
  display: flex;
  align-items: center;
  padding: 8px;
  margin-bottom: 24px;
  margin-top: 24px;

  ${media.small`
    height: auto;
    min-height: 90px;
    flex-direction: column;
    align-items: flex-start;
  `}
`

const TitlebarContent = styled.div`
  min-width: 120px;
  width: 100%;
  display: flex;
  gap: 8px;
  align-items: center;
  overflow: hidden;
`

const TitleTypography = styled(Typography)`
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${media.small`
    font-size: 1rem;
  `}
`

const TruncatedTitle = styled(TitleTypography)`
  max-width: 40vw;

  ${media.small`
    max-width: 40vw;
  `}
`

const ParentTitleContainer = styled(WorkspacedLink)`
  display: flex;
  align-items: center;
  padding: 4px;
  border-radius: 4px;
`

const SeperatorContainer = styled.div`
  color: ${(props) => props.theme.palette.text.secondary};
  height: 24px;
  margin: 0 8px;
`

const ShipmentTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const ShipmentTitleContent = styled.div`
  display: flex;
  align-items: center;
`

const TitleContent = styled.div`
  width: 100%;
  max-width: 75vw;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${media.small`
    max-width: 100vw;
    padding-bottom: 8px;
  `}
`

const MetaContent = styled.div`
  width: 100%;
  padding: 24px 12px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.small`
    padding: 0;
    align-items: stretch;
  `}
`

const StyledStickyNote = styled(StickyNote)`
  min-width: 220px;
`

const TabContainer = styled.div`
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const DetailsCard = styled(Card)`
  width: 100%;
  padding: 8px 16px;
`

const KeyFieldsContainer = styled.div`
  margin: 4px 0 16px 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, 190px);
  grid-gap: 8px;
  width: 100%;

  ${media.large`
    grid-template-columns: repeat(auto-fill, 216px);
  `}

  ${media.small`
    grid-template-columns: repeat(auto-fill, 110px);
  `}
`

const ContianerProfileImage = styled(Avatar)`
  flex-shrink: 0;
  margin-right: 8px;
  height: 40px;
  width: 40px;
  border: 4px solid ${(props) => props.theme.palette.background.paper};
  border-radius: 8px;
`

const ProfileImage = styled(Avatar)`
  margin-right: 16px;
  height: 100px;
  width: 100px;
  border: 4px solid ${(props) => props.theme.palette.background.paper};
`

const StagesContainer = styled.div`
  width: 100%;
  padding-bottom: 16px;
`

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
`

interface NestedListItemProps {
  currentShipment: ShipmentShowHeroCardQuery['response']['shipmentShow']
  item: ShipmentShowHeroCardContainerQuery['response']['shipmentShow']['container']['shipmentItems'][number]
  style?: Record<any, any>
}

const NestedListItem = ({ currentShipment, item, style }: NestedListItemProps) => {
  const [open, setOpen] = useState(true)
  const wsNavigate = useWorkspaceNavigate()

  const toggle = (e: React.MouseEvent) => {
    e.stopPropagation()
    setOpen((o) => !o)
  }

  const vin = (item.lines || [])?.find((line) => line.key === 'vin')

  return (
    <>
      <ListItem
        selected={currentShipment?.slug === item.slug}
        style={style}
        button
        onClick={() => {
          wsNavigate(`/shipment/${item.slug}/home`)
        }}
      >
        <ListItemIcon>
          {item.badges?.map((badge) => (
            <Badge key={badge.key} data={badge} />
          ))}{' '}
        </ListItemIcon>
        <ListItemText primary={item.title} secondary={vin ? <SemanticLine key={'vin'} data={vin} /> : <></>} />
        {(item.children || [])?.length > 0 && <IconButton onClick={toggle}>{open ? <ExpandLess /> : <ExpandMore />}</IconButton>}
      </ListItem>
      {!!item.children?.length && (
        <Collapse in={open} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            {item.children?.map((childItem) => (
              <NestedListItem style={{ paddingLeft: 32 }} currentShipment={currentShipment} item={childItem as any} key={childItem.slug} />
            ))}
          </List>
        </Collapse>
      )}
    </>
  )
}

const ShipmentMenu: React.FC<any> = ({ slug, shipmentMenuRef, setContainer, setAllPackages }: any) => {
  const { shipmentShow: shipment } = useLazyLoadQueryWithSubscription<ShipmentShowHeroCardContainerQuery>(
    containerQuery,
    {
      slug: slug,
    },
    { subscriptionOptions: { entityType: 'shipments', entitySlug: slug } }
  )

  useEffect(() => {
    setContainer(shipment.container)
    setAllPackages(shipment.allPackages)
  }, [shipment])

  const [open, setOpen] = useState(false)
  const handleClose = () => {
    setOpen(false)
  }

  return shipment.container.shipmentItems.length > 0 ? (
    <ShipmentTitleContainer>
      <IconButton onClick={() => setOpen(true)}>
        <ArrowDropDown />
      </IconButton>
      <Menu
        PaperProps={{ style: { width: shipmentMenuRef.current?.clientWidth, maxHeight: 'calc(100vh - 184px)' } }}
        open={open}
        anchorEl={shipmentMenuRef.current}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleClose}
      >
        <List onClick={handleClose}>
          {shipment.container.shipmentItems?.map((ship) => (
            <NestedListItem key={ship.slug} currentShipment={shipment as any} item={ship} />
          ))}
        </List>
      </Menu>
    </ShipmentTitleContainer>
  ) : null
}

const ShipmentShowHeroCard: React.FC<Props> & { Skeleton: React.FC } = ({ slug, activeTab, onEdit, renderContext }) => {
  const shipmentMenuRef = useRef<null | HTMLElement>(null)
  const maxFields = isSmall() ? 4 : isMedium() ? 9 : 15
  const KeyFieldNotAllow = ['Payment Date', 'Total Revenue', 'Documents']

  const { itemShow: item, shipmentShow: shipment } = useLazyLoadQueryWithSubscription<ShipmentShowHeroCardQuery>(
    query,
    {
      slug: slug,
      itemType: 'shipment',
    },
    { subscriptionOptions: { entityType: 'shipments', entitySlug: slug } }
  )

  const value = tabs.findIndex((t) => t.value === activeTab)
  const wsNavigate = useWorkspaceNavigate()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, isShipmentSwitchPending] = unstable_useTransition()

  const onTabChange = (e: React.ChangeEvent<unknown>, newValue: number) => {
    wsNavigate(`/shipment/${item.slug}/${tabs[newValue].value}`)
  }

  const [container, setContainer] = useState<ShipmentShowHeroCardContainerQuery['response']['shipmentShow']['container'] | null>(null)
  const [allPackages, setAllPackages] = useState<ShipmentShowHeroCardContainerQuery['response']['shipmentShow']['allPackages'] | null>(null)

  const renderContainer = () => (
    <Box display='flex' alignItems='center'>
      <ParentTitleContainer
        {...{
          as: container ? undefined : 'div',
          to: container ? `/container/${container.slug}/home` : undefined,
        }}
      >
        {container && <ContianerProfileImage src={container.imageUrl || undefined} />}
        <TitlebarContent>
          {container ? (
            <TitleTypography color='textSecondary' variant='subtitle1'>
              {container.containerNumber}
            </TitleTypography>
          ) : (
            <Skeleton style={{ flexGrow: 1 }} variant='text' />
          )}
        </TitlebarContent>
      </ParentTitleContainer>
      <SeperatorContainer>
        <ChevronRight />
      </SeperatorContainer>
    </Box>
  )

  const packageIcon = <RedeemOutlinedIcon color='secondary' />
  return (
    <>
      {renderContext === 'page' && <ReactHeadTitle>Shipment - {item.title}</ReactHeadTitle>}
      <ArchivedBanner isArchived={shipment.isArchived} />
      <Hero backgroundColor='#7d7d7d'>
        <ContentRoot>
          <NonStageContent>
            <TitleContent>
              <Titlebar ref={shipmentMenuRef} variant='outlined'>
                {!isSmall() && renderContainer()}
                <ShipmentTitleContainer style={{ width: '100%' }}>
                  <ShipmentTitleContent>
                    <ProfileImage src={item?.image?.url as string} />
                    <Box display='flex' flexDirection='column' justifyContent='space-between' flex={isSmall() ? 1 : undefined}>
                      {isSmall() && renderContainer()}
                      <TitlebarContent>
                        <TruncatedTitle title={item.title} variant='h5'>
                          {item.title}
                        </TruncatedTitle>
                        {item.badges?.map((badge) => (
                          <Badge key={badge.key} data={badge} />
                        ))}
                        {!!allPackages?.length && !isSmall() && (
                          <>
                            <ChevronRight />
                            {packageIcon}
                          </>
                        )}
                      </TitlebarContent>
                      {item.lines?.map((line) => (
                        <SemanticLine key={line.key} data={line} />
                      ))}
                    </Box>
                  </ShipmentTitleContent>
                  <Suspense
                    fallback={
                      <IconButton disabled>
                        <CircularProgress size={20} />
                      </IconButton>
                    }
                  >
                    <ShipmentMenu setAllPackages={setAllPackages} setContainer={setContainer} slug={slug} shipmentMenuRef={shipmentMenuRef} />
                  </Suspense>
                </ShipmentTitleContainer>
              </Titlebar>
              <DetailsCard variant='outlined'>
                {item.keyFields && (
                  <KeyFieldsContainer>
                    {item.keyFields
                      ?.slice(0, maxFields)
                      ?.filter((field) => !KeyFieldNotAllow.includes(field.key))
                      .map((field) => (
                        <KeyField key={field.key} data={field} />
                      ))}
                  </KeyFieldsContainer>
                )}
              </DetailsCard>
            </TitleContent>
            <MetaContent>
              {shipment.subStages.map((subStage, idx) => (
                <SubStage key={subStage.stageIdx || idx} data={subStage} entityType='shipments' entitySlug={slug} />
              ))}
              {item.stickyNoteContent && <StyledStickyNote note={item.stickyNoteContent} type='internal' />}
              {item.stickyNoteContentByExternal && <StyledStickyNote note={item.stickyNoteContentByExternal} type='external' />}
            </MetaContent>
          </NonStageContent>
          <StagesContainer>
            <ShipmentWorkflow shipment={shipment} />
          </StagesContainer>
        </ContentRoot>
      </Hero>
      {/* {shipment.isArchived && <ArchiveDivider />} */}
      <Hero backgroundColor='transparent'>
        <>
          <TabContainer>
            <TabsUI isWhiteBackground tabs={tabs} value={value} onChange={onTabChange} />
            <ActionsContainer>
              <Suspense fallback={null}>
                <Watchers entityType='shipments' entitySlug={slug} />
              </Suspense>
              {shipment.stage === 'done' && <ArchiveActions isArchive={shipment.isArchived} entityType='shipments' entitySlug={slug} />}
              {!shipment.isArchived && (
                <Button variant='contained' startIcon={<Edit />} onClick={onEdit}>
                  Edit
                </Button>
              )}
            </ActionsContainer>
          </TabContainer>
        </>
      </Hero>
    </>
  )
}

const query = graphql`
  query ShipmentShowHeroCardQuery($slug: String!, $itemType: ShowItemTypeEnum!) {
    itemShow(slug: $slug, type: $itemType) {
      slug
      title
      stickyNoteContent
      image {
        url
        ...ItemImage_data
      }
      badges {
        key
        ...Badge_data
      }
      lines {
        key
        ...SemanticLine_data
      }
      keyFields {
        key
        ...KeyField_data
      }
    }

    shipmentShow(slug: $slug) {
      slug
      isArchived
      stage
      shipmentKey
      subStages {
        stageIdx
        ...SubStage_data
      }
      ...ShipmentWorkflow_shipment
    }
  }
`
const containerQuery = graphql`
  query ShipmentShowHeroCardContainerQuery($slug: String!) {
    shipmentShow(slug: $slug) {
      slug
      allPackages {
        slug
        shipmentKey
        description
        shipmentType
        vin
      }
      container {
        slug
        containerNumber
        imageUrl
        shipmentItems {
          slug
          title
          children {
            slug
            title
            badges {
              key
              ...Badge_data
            }
            lines {
              key
              parts {
                ...SemanticString_data
              }
              ...SemanticLine_data
            }
          }
          badges {
            key
            ...Badge_data
          }
          image {
            url
          }
          lines {
            key
            parts {
              ...SemanticString_data
            }
            ...SemanticLine_data
          }
        }
      }
    }
  }
`

ShipmentShowHeroCard.Skeleton = () => {
  const numFields = isSmall() ? 4 : 9
  return (
    <>
      <Hero backgroundColor='transparent'>
        <ContentRoot>
          <NonStageContent>
            <TitleContent>
              <Titlebar variant='outlined'>
                {!isSmall() && (
                  <Skeleton style={{ marginRight: '16px' }}>
                    <TitleTypography variant='subtitle1'>...........................................</TitleTypography>
                  </Skeleton>
                )}
                <ShipmentTitleContainer>
                  <ShipmentTitleContent>
                    <Skeleton variant='circle' style={{ marginRight: '16px', height: '100px', width: '100px' }} />
                    <div>
                      <TitlebarContent>
                        <Skeleton>
                          <TitleTypography variant='h5'>..............................................</TitleTypography>
                        </Skeleton>
                      </TitlebarContent>
                      <SemanticLine.Skeleton />
                    </div>
                  </ShipmentTitleContent>
                </ShipmentTitleContainer>
              </Titlebar>
              <DetailsCard variant='outlined'>
                <KeyFieldsContainer>
                  {[...Array(numFields)].map((idx) => (
                    <Skeleton key={idx} height='44px' />
                  ))}
                </KeyFieldsContainer>
              </DetailsCard>
            </TitleContent>
            <MetaContent>
              <Skeleton variant='rect' height='36px' width='200px' style={{ marginBottom: '8px' }} />
              <Skeleton variant='rect' height='36px' width='200px' style={{ marginBottom: '8px' }} />
            </MetaContent>
          </NonStageContent>
          <StagesContainer>
            <div style={{ height: '64px' }} />
          </StagesContainer>
        </ContentRoot>
      </Hero>
      <Hero backgroundColor='transparent'>
        <TabContainer>
          {/* no TabsUI skeleton */}
          <span></span>
          <ActionsContainer>
            <Button variant='contained' startIcon={<Edit />} disabled>
              Edit
            </Button>
          </ActionsContainer>
        </TabContainer>
      </Hero>
    </>
  )
}

export default ShipmentShowHeroCard
