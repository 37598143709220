/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ShareNoteQueryVariables = {
    slug: string;
};
export type ShareNoteQueryResponse = {
    readonly showNote: {
        readonly slug: string;
        readonly entitySlug: string;
        readonly entityType: string;
        readonly roleVisibilities: ReadonlyArray<{
            readonly role: string;
            readonly roleShortTitle: string;
            readonly roleTitle: string;
            readonly visible: boolean;
        }>;
    };
};
export type ShareNoteQuery = {
    readonly response: ShareNoteQueryResponse;
    readonly variables: ShareNoteQueryVariables;
};



/*
query ShareNoteQuery(
  $slug: String!
) {
  showNote(slug: $slug) {
    slug
    entitySlug
    entityType
    roleVisibilities {
      role
      roleShortTitle
      roleTitle
      visible
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "slug",
        "variableName": "slug"
      }
    ],
    "concreteType": "Note",
    "kind": "LinkedField",
    "name": "showNote",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "slug",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "entitySlug",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "entityType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "RoleVisibility",
        "kind": "LinkedField",
        "name": "roleVisibilities",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "role",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "roleShortTitle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "roleTitle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "visible",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ShareNoteQuery",
    "selections": (v1/*: any*/),
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ShareNoteQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a651093e5266d260d1ffc0ffb8e60fa8",
    "id": null,
    "metadata": {},
    "name": "ShareNoteQuery",
    "operationKind": "query",
    "text": "query ShareNoteQuery(\n  $slug: String!\n) {\n  showNote(slug: $slug) {\n    slug\n    entitySlug\n    entityType\n    roleVisibilities {\n      role\n      roleShortTitle\n      roleTitle\n      visible\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '4b62225da86a98cd6bd45e9b64c9ab74';
export default node;
