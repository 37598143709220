import React, { Suspense, useState } from 'react'
import { graphql } from 'babel-plugin-relay/macro'

import { DialogTitle, DialogContent } from '@material-ui/core'
import { useFragment, useLazyLoadQuery } from 'react-relay/hooks'
import { EditSettingDialogQuery, SettingTypeEnum } from '../__generated__/EditSettingDialogQuery.graphql'
import { Skeleton, Alert } from '@material-ui/lab'
import { Form } from 'formik'
import styled from 'styled-components'
import { EditSettingDialogMutation } from '../__generated__/EditSettingDialogMutation.graphql'
import useNiceMutation from '../mutations/useNiceMutation'
import InputField from './Form/InputField'
import { media } from '../theme'
import NiceFormik from './Form/NiceFormik'
import DialogFormActionBar from './Form/DialogFormActionBar'
import OdysseyDialog from './OdysseyDialog'
import { EditSettingDialog_settingUnion$key } from '../__generated__/EditSettingDialog_settingUnion.graphql'
interface Props {
  slug: string
  // type: EditSettingDialogQueryVariables['type']
  type: SettingTypeEnum
  title: string
  open: boolean
  onClose: () => void
  onEdit: () => void
}

const StyledForm = styled(Form)`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`
const FieldsContainer = styled.div`
  margin-bottom: 16px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 16px;

  ${media.medium`
    grid-template-columns: repeat(1, 1fr);
  `}

  ${media.small`
    grid-template-columns: auto;
  `}
`

const EditSettingDialog: React.FC<Props> = (props) => {
  return (
    <OdysseyDialog
      open={props.open}
      maxWidth='sm'
      fullWidth
      onClose={props.onClose}
      scroll='paper'
      style={{ maxWidth: '640px', margin: 'auto' }}
    >
      <Suspense fallback={<FormSkeleton {...props} />}>
        <UpdateForm {...props} />
      </Suspense>
    </OdysseyDialog>
  )
}

const UpdateForm: React.FC<Props> = ({ slug, type, title, onClose, onEdit }) => {
  const data = useLazyLoadQuery<EditSettingDialogQuery>(query, { type: type, slug: slug })
  const fields = data.infoUpdateSettingForm.formSchema.fieldSet
  const [mutationError, setMutationError] = useState<null | string>(null)
  const [commit] = useNiceMutation<EditSettingDialogMutation>(mutation)
  const initialValue = useFragment(settingUnionFragment, data.infoUpdateSettingForm.initialValue as EditSettingDialog_settingUnion$key)

  return (
    <NiceFormik
      initialValues={initialValue || {}}
      onSubmit={(values, actions) => {
        commit({
          variables: {
            input: {
              slug: slug,
              type: type,
              fields: Object.entries(values).map((value) => ({
                name: value[0],
                value: value[1] === null ? '' : (value[1] as string).toString(),
              })),
            },
          },
          onCompleted: (res, errors) => {
            actions.setSubmitting(false)
            if (errors) {
              setMutationError(errors.map((err) => err.message).join(', '))
            } else {
              onEdit()
              onClose()
            }
          },
        })
      }}
      formSchema={fields}
    >
      {({ submitForm }) => (
        <StyledForm>
          <DialogTitle>{title}</DialogTitle>
          {mutationError && <Alert severity='error'>{mutationError}</Alert>}
          <DialogContent dividers={true}>
            <FieldsContainer>
              {fields.map((field, index) => {
                return <InputField key={index} field={field} disabled={field.disabled} />
              })}
            </FieldsContainer>
          </DialogContent>
          <DialogFormActionBar
            onCancel={() => onClose()}
            onSubmit={() => {
              submitForm()
            }}
            cancelCta='Cancel'
            saveCta='Save'
          />
        </StyledForm>
      )}
    </NiceFormik>
  )
}

const FormSkeleton: React.FC<Props> = ({ title, onClose }) => (
  <>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <Skeleton variant='rect' height='50px' />
      <Skeleton variant='rect' height='50px' style={{ marginTop: '8px' }} />
    </DialogContent>
    <DialogFormActionBar.Skeleton onCancel={onClose} />
  </>
)

const mutation = graphql`
  mutation EditSettingDialogMutation($input: infoUpdateSettingInput!) {
    infoUpdateSetting(input: $input) {
      clientMutationId
      settingItem {
        ...SettingsItem_settingsItem
      }
    }
  }
`

const query = graphql`
  query EditSettingDialogQuery($type: SettingTypeEnum!, $slug: String!) {
    infoUpdateSettingForm(type: $type, slug: $slug) {
      formSchema {
        fieldSet {
          name
          type
          required
          title
          description
          format
          enum
          enumTitles
          disabled
        }
      }
      initialValue {
        ...EditSettingDialog_settingUnion
      }
    }
  }
`

const settingUnionFragment = graphql`
  fragment EditSettingDialog_settingUnion on SettingsUnion {
    ... on RoleSetting {
      name
      forContainers
      forShipments
    }

    ... on PortSetting {
      code
      name
      category
    }

    ... on LanguageSetting {
      code
      name
    }

    ... on ShippingLineSetting {
      name
      primaryEmails
      secondaryEmails
    }

    ... on DocumentTemplateSetting {
      docType
      title
      html
      category
    }

    ... on EmailTemplateSetting {
      title
      defaultRecipientRoles
      subjectPl
      subjectDe
      subjectEn
      subjectUa
      bodyPl
      bodyDe
      bodyEn
      bodyUa
      emailSignature
    }

    ... on TemplateSetting {
      title
      defaultRecipientRoles
      subjectPl
      subjectDe
      subjectEn
      subjectUa
      bodyPl
      bodyDe
      bodyEn
      bodyUa
      messageSignature
    }

    ... on DefaultFolderSetting {
      name
      folderType
    }

    ... on DocumentTypeSetting {
      title
      documentName
      oldDocumentName
      description
      category
    }

    ... on CustomsTypeSetting {
      name
      category
    }

    ... on MiscellaneousSettingSetting {
      label
      value
      description
    }

    ... on ConfigSettingSetting {
      label
      apply
      description
      category
      emailTemplateName
      emailTemplateSlug
    }

    ... on ShipmentTypeSetting {
      code
      portHandlingCharge
      vatChargePercent
      dutiesRatePercent
    }

    ... on RateCardSetting {
      name
      defaultCleaning
      standardCustomsClearance
      fiscalCustomsClearance
      transitCustomsClearance
      physicalCustomsReview
      forklift
      specialHandling
      twoPackCarDischarge
      threePackCarDischarge
      fourPackCarDischarge
      roroCarDischarge
      motorcycleDischarge
      freeWarehousingDays
      costWarehousing
      xRay
      storage
      cleaning
    }
  }
`

export default EditSettingDialog
