/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SettingTypeEnum = "config_settings" | "customs_types" | "default_folders" | "document_templates" | "document_types" | "email_templates" | "languages" | "miscellaneous_settings" | "ports" | "rate_cards" | "roles" | "shipment_types" | "shipping_lines" | "templates" | "%future added value";
export type SettingsItem_settingsItem = {
    readonly slug: string;
    readonly title: string;
    readonly settingType: SettingTypeEnum;
    readonly image: {
        readonly " $fragmentRefs": FragmentRefs<"SettingItemImage_data">;
    } | null;
    readonly lines: ReadonlyArray<{
        readonly key: string;
        readonly " $fragmentRefs": FragmentRefs<"SemanticLine_data">;
    }> | null;
    readonly keyFields: ReadonlyArray<{
        readonly key: string;
        readonly " $fragmentRefs": FragmentRefs<"KeyField_data">;
    }> | null;
    readonly showFields: ReadonlyArray<{
        readonly key: string;
        readonly " $fragmentRefs": FragmentRefs<"ShowField_data">;
    }> | null;
    readonly bodyFields: ReadonlyArray<{
        readonly key: string;
        readonly " $fragmentRefs": FragmentRefs<"BodyField_data">;
    }> | null;
    readonly attachments: ReadonlyArray<{
        readonly slug: string;
        readonly " $fragmentRefs": FragmentRefs<"Attachment_attachment">;
    }> | null;
    readonly " $refType": "SettingsItem_settingsItem";
};
export type SettingsItem_settingsItem$data = SettingsItem_settingsItem;
export type SettingsItem_settingsItem$key = {
    readonly " $data"?: SettingsItem_settingsItem$data;
    readonly " $fragmentRefs": FragmentRefs<"SettingsItem_settingsItem">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SettingsItem_settingsItem",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "settingType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SettingItemImage_data"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SemanticLine",
      "kind": "LinkedField",
      "name": "lines",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SemanticLine_data"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "KeyField",
      "kind": "LinkedField",
      "name": "keyFields",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "KeyField_data"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ShowField",
      "kind": "LinkedField",
      "name": "showFields",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ShowField_data"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BodyField",
      "kind": "LinkedField",
      "name": "bodyFields",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BodyField_data"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Attachment",
      "kind": "LinkedField",
      "name": "attachments",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Attachment_attachment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SettingsItem",
  "abstractKey": null
};
})();
(node as any).hash = '73cfde2e2ad9e16fd3814d641e21df18';
export default node;
