import { Dialog, DialogActions, DialogContent, Step, StepButton, Stepper, TextField } from '@material-ui/core'
import { graphql } from 'babel-plugin-relay/macro'
import React, { useEffect, useState } from 'react'
import { useFragment } from 'react-relay/hooks'
import Button from './Button'
import LinkedContactsCard from './LinkedContactsCard'
import { ExternalShipmentInfoCard_shipment$key } from '../__generated__/ExternalShipmentInfoCard_shipment.graphql'
import ExternalFoldersCard from './File/ExternalFoldersCard'
import LoadingDots from './LoadingDots'
import useNiceMutation from '../mutations/useNiceMutation'
import { useSnackbar } from 'notistack'
import { ExternalUploadDocumentsWizardSendSignatureMutation } from '../__generated__/ExternalUploadDocumentsWizardSendSignatureMutation.graphql'
import { ExternalUploadDocumentsWizardCreateDocsMutation } from '../__generated__/ExternalUploadDocumentsWizardCreateDocsMutation.graphql'
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'

interface Props {
  slug: string
  open: boolean
  shipment: ExternalShipmentInfoCard_shipment$key
  onClose: () => void
}

const DOCUMENT_NAME_STEP_1 = 'Bill of Sale'

const ExternalUploadDocumentsWizard: React.FC<Props> = ({ slug, shipment: data, open, onClose }) => {
  const shipment = useFragment(fragment, data)
  const completed = [!shipment.requiredDocuments?.includes(DOCUMENT_NAME_STEP_1), false]
  const [steps, setSteps] = useState(['Upload Bill of Sale', 'Add Customer'])

  const [activeStep, setActiveStep] = React.useState(0)

  const handleDialogClose = () => {
    setActiveStep(0)
    onClose()
  }

  return (
    <>
      <Dialog open={open} maxWidth='md' onClose={handleDialogClose} scroll='paper' style={{ margin: 'auto' }}>
        <Stepper nonLinear activeStep={activeStep}>
          {steps.map((label, index) => {
            return (
              <Step key={label}>
                <StepButton onClick={() => setActiveStep(index)} completed={completed[index]}>
                  {label}
                </StepButton>
              </Step>
            )
          })}
        </Stepper>
        {activeStep === 0 && (
          <UploadBillOfSaleStep shipment={data} setActiveStep={setActiveStep} slug={slug} activeStep={activeStep} onClose={onClose} />
        )}
        {activeStep === 1 && (
          <AddCustomerStep
            shipment={data}
            setSteps={setSteps}
            setActiveStep={setActiveStep}
            slug={slug}
            activeStep={activeStep}
            onClose={onClose}
          />
        )}
        {activeStep === 2 && (
          <AddPoaTemplate
            shipment={data}
            setSteps={setSteps}
            setActiveStep={setActiveStep}
            slug={slug}
            activeStep={activeStep}
            onClose={onClose}
          />
        )}
      </Dialog>
    </>
  )
}

interface StepContentProps {
  slug: string
  activeStep: number
  setActiveStep: (value: React.SetStateAction<number>) => void
  onClose: () => void
  shipment: ExternalShipmentInfoCard_shipment$key
}

const UploadBillOfSaleStep: React.FC<StepContentProps> = ({ setActiveStep, shipment, slug }) => {
  return (
    <>
      <DialogContent>
        <ExternalFoldersCard
          key='documents'
          folderType='documents'
          // @ts-ignore
          folders={shipment.folders}
          title='Documents'
          entityType='shipments'
          entitySlug={slug}
          // documentType={DOCUMENT_NAME_STEP_1}
        />
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={() => setActiveStep((prev) => prev + 1)}>
          Next: Add Customer
        </Button>
      </DialogActions>
    </>
  )
}

interface AddCustomerProps {
  slug: string
  activeStep: number
  setActiveStep: (value: React.SetStateAction<number>) => void
  onClose: () => void
  shipment: ExternalShipmentInfoCard_shipment$key
  setSteps: (value: React.SetStateAction<string[]>) => void
}

const AddPoaTemplate: React.FC<AddCustomerProps> = ({ setActiveStep, shipment, onClose }) => {
  const [commitGenerateExternal, externalGenerateIsInFlight] =
    useNiceMutation<ExternalUploadDocumentsWizardCreateDocsMutation>(externalGenerateMutation)
  const [commitSendSignature, isProcessingSendSignature] =
    useNiceMutation<ExternalUploadDocumentsWizardSendSignatureMutation>(sendForSignatureMutation)
  const { enqueueSnackbar } = useSnackbar()
  const foldersConnection = useFragment(fragment2, shipment.folders)

  // const isPoaDocsGenerated = foldersConnection.nodes.some((node: { attachments: { documentType: string }[]; parentType: string }) => {
  //   const attachment_present = node.attachments.some((attachment: { documentType: string }) =>
  //     ['vehicle_poa_template', 'general_poa_template', 'fiscal_poa_template'].includes(attachment.documentType)
  //   )
  //   return node.parentType === 'documents' && attachment_present
  // })

  let hasErrors = false
  const [email, setEmail] = useState<string>('')
  const [selectedLanguage, setSelectedLanguage] = useState<string>('')
  const [selectedCustomerType, setSelectedCustomerType] = useState<string>('')
  const [customerTypeError, setCustomerTypeError] = useState<boolean>(true)
  const [emailError, setEmailError] = useState<boolean>(true)
  const [esignLink, setEsignLink] = useState<string | null>(null)

  const processESign = async () => {
    externalGenerateDoc()
    setCustomerTypeError(false)
    setEmailError(false)
    // if (!isPoaDocsGenerated) externalGenerateDoc()
    // else sendForSignature()
  }
  const sendForSignature = () => {
    commitSendSignature({
      variables: {
        input: {
          // @ts-ignore
          entitySlug: shipment.slug,
          entityType: 'shipments',
          customerType: selectedCustomerType,
          language: selectedLanguage,
          email: email,
        },
      },
      onCompleted: ({ insignRegisterDocumentForSigning }, err) => {
        setEsignLink(insignRegisterDocumentForSigning?.esignLink ?? '')
        if (!err) {
          enqueueSnackbar(`Documents successfully sent for e-Signature`, { variant: 'success' })
        }
      },
    })
  }
  const externalGenerateDoc = () => {
    commitGenerateExternal({
      variables: {
        input: {
          entityType: 'shipments',
          // @ts-ignore
          entitySlug: shipment.slug,
          // @ts-ignore
          folderSlug: foldersConnection.nodes.filter((e) => e.parentType === 'documents')[0].slug,
          templateSlug: 'poa',
          customerType: selectedCustomerType,
          language: selectedLanguage,
        },
      },
      onCompleted: (_, errors) => {
        if (errors) {
          hasErrors = true
        } else {
          sendForSignature()
        }
        // onClose()
      },
    })
  }

  const copyToClipboard = () => {
    if (esignLink) {
      navigator.clipboard.writeText(esignLink)
      enqueueSnackbar('e-Sign link copied to clipboard', { variant: 'success' })
    }
  }

  const handleClose = () => {
    setActiveStep(0)
    onClose()
  }

  return (
    <>
      <DialogContent>
        <FormControl fullWidth margin='normal'>
          <InputLabel>Customer type</InputLabel>
          <Select
            value={selectedCustomerType}
            onChange={(e) => {
              setSelectedCustomerType(e.target.value as string)
              setCustomerTypeError(false)
            }}
          >
            <MenuItem key={'company'} value={'company'}>
              Company
            </MenuItem>
            <MenuItem key={'person'} value={'person'}>
              Person
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth margin='normal'>
          <InputLabel>POA Language</InputLabel>
          <Select value={selectedLanguage} onChange={(e) => setSelectedLanguage(e.target.value as string)}>
            <MenuItem value={'en'}>English</MenuItem>
            <MenuItem value={'de'}>Deutsch</MenuItem>
            <MenuItem value={'pl'}>Polski</MenuItem>
            <MenuItem value={'ua'}>Ukrainian</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth margin='normal'>
          <TextField
            size='small'
            variant='standard'
            value={email}
            label='Email'
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setEmail(e.target.value as string)
              setEmailError(false)
            }}
          />
        </FormControl>

        {esignLink && (
          <FormControl fullWidth margin='normal'>
            <TextField
              label='e-Sign Link'
              value={esignLink}
              variant='outlined'
              InputProps={{
                endAdornment: (
                  <Button variant='contained' onClick={copyToClipboard}>
                    Copy
                  </Button>
                ),
              }}
              disabled
            />
          </FormControl>
        )}
      </DialogContent>
      <DialogActions>
        {esignLink ? (
          <Button variant='contained' onClick={handleClose}>
            {'Close'}
          </Button>
        ) : (
          <Button
            variant='contained'
            onClick={processESign}
            disabled={externalGenerateIsInFlight || isProcessingSendSignature || hasErrors || customerTypeError || emailError}
          >
            {externalGenerateIsInFlight || isProcessingSendSignature ? <LoadingDots variant='primary' /> : 'Send POA documents for e-Sign'}
          </Button>
        )}
      </DialogActions>
    </>
  )
}

const AddCustomerStep: React.FC<AddCustomerProps> = ({ setActiveStep, shipment, onClose, setSteps }) => {
  const [commitGenerateExternal, externalGenerateIsInFlight] =
    useNiceMutation<ExternalUploadDocumentsWizardCreateDocsMutation>(externalGenerateMutation)
  const [commitSendSignature, isProcessingSendSignature] =
    useNiceMutation<ExternalUploadDocumentsWizardSendSignatureMutation>(sendForSignatureMutation)
  const { enqueueSnackbar } = useSnackbar()
  // @ts-ignore
  const foldersConnection = useFragment(fragment2, shipment.folders)
  const [hasCustomer, setHasCustomer] = useState<boolean>(false)

  useEffect(() => {
    if (hasCustomer) {
      setSteps(['Upload Bill of Sale', 'Add Customer'])
    } else {
      setSteps((prev: string[]) => {
        if (!prev.includes('Choose POA template')) {
          return [...prev, 'Choose POA template']
        }
        return prev
      })
    }
  }, [hasCustomer, setSteps])

  const handleHasCustomerChange = (value: boolean) => {
    setHasCustomer(value)
  }

  // const isPoaDocsGenerated = foldersConnection.nodes.some((node: { attachments: { documentType: string }[]; parentType: string }) => {
  //   const attachment_present = node.attachments.some((attachment: { documentType: string }) =>
  //     ['vehicle_poa_template', 'general_poa_template', 'fiscal_poa_template'].includes(attachment.documentType)
  //   )
  //   return node.parentType === 'documents' && attachment_present
  // })

  const processESign = async () => {
    externalGenerateDoc()
    // if (!isPoaDocsGenerated) externalGenerateDoc()
    // else sendForSignature()
  }
  const sendForSignature = () => {
    commitSendSignature({
      variables: {
        input: {
          // @ts-ignore
          entitySlug: shipment.slug,
          entityType: 'shipments',
        },
      },
      onCompleted: (_, err) => {
        if (!err) {
          enqueueSnackbar(`Documents successfully sent for e-Signature`, { variant: 'success' })
        }
      },
    })
  }
  const externalGenerateDoc = () => {
    commitGenerateExternal({
      variables: {
        input: {
          entityType: 'shipments',
          // @ts-ignore
          entitySlug: shipment.slug,
          // @ts-ignore
          folderSlug: foldersConnection.nodes.filter((e) => e.parentType === 'documents')[0].slug,
          templateSlug: 'poa',
        },
      },
      onCompleted: (_, errors) => {
        if (errors) {
          hasErrors = true
        } else {
          sendForSignature()
        }
        onClose()
      },
    })
  }

  let hasErrors = false

  return (
    <>
      <DialogContent>
        <LinkedContactsCard
          key='contacts'
          // @ts-ignore
          linkedContacts={shipment.linkedContacts}
          // @ts-ignore
          draftContacts={shipment.draftContacts}
          // @ts-ignore
          draftLinkedContactRoles={shipment.draftLinkedContactRoles}
          // @ts-ignore
          entitySlug={shipment.slug}
          entityType='shipment'
          //eslint-disable-next-line jsx-a11y/aria-role
          role='customer'
          onHasCustomerChange={handleHasCustomerChange}
        />
      </DialogContent>
      <DialogActions>
        {hasCustomer ? (
          <Button variant='contained' onClick={processESign} disabled={externalGenerateIsInFlight || hasErrors}>
            {externalGenerateIsInFlight || isProcessingSendSignature ? <LoadingDots variant='primary' /> : 'Send POA documents for e-Sign'}
          </Button>
        ) : (
          <Button variant='contained' onClick={() => setActiveStep((prev) => prev + 1)}>
            {hasCustomer ? 'Next' : 'Skip'}
          </Button>
        )}
      </DialogActions>
    </>
  )
}

const fragment = graphql`
  fragment ExternalUploadDocumentsWizard_shipment on ExternalShipment {
    slug
    requiredDocuments
  }
`

const externalGenerateMutation = graphql`
  mutation ExternalUploadDocumentsWizardCreateDocsMutation($input: infoGeneratePoaDocumentForExternalInput!) {
    infoGeneratePoaDocumentForExternal(input: $input) {
      clientMutationId
    }
  }
`

const sendForSignatureMutation = graphql`
  mutation ExternalUploadDocumentsWizardSendSignatureMutation($input: insignRegisterDocumentForSigningInput!) {
    insignRegisterDocumentForSigning(input: $input) {
      clientMutationId
      esignLink
    }
  }
`

const fragment2 = graphql`
  fragment ExternalUploadDocumentsWizard_folders on FolderConnection {
    nodes {
      slug
      activeTicket
      parentType
      attachments {
        id
        documentType
      }
    }
  }
`

export default ExternalUploadDocumentsWizard
