import { SettingTypeEnum } from '../../__generated__/AddSettingDialogQuery.graphql'

export type SettingType = SettingTypeEnum
export type TabType = SettingType | 'home'

export interface WorkspaceTab {
  label: string
  type?: SettingType
  value: string
  entity: string
  description: string
}
export const tabDefinitions: { [key: string]: WorkspaceTab } = {
  home: { label: 'Home', value: 'home', entity: 'Setting', description: '' },
  roles: { label: 'Roles', value: 'roles', type: 'roles', entity: 'Role', description: 'Custom Roles that can be assigned to various users.' },
  shipping_lines: {
    label: 'Shipping Lines',
    value: 'shipping-lines',
    type: 'shipping_lines',
    entity: 'Shipping Line',
    description: 'A business that operates ships',
  },
  shipment_types: {
    label: 'Shipment Types',
    value: 'shipment-types',
    type: 'shipment_types',
    entity: 'Shipment Type',
    description: 'Shipment types.',
  },
  ports: { label: 'Ports', value: 'ports', type: 'ports', entity: 'Port', description: 'Harbour to mark as source or destination.' },
  rate_cards: {
    label: 'Rate Cards',
    value: 'rate-cards',
    type: 'rate_cards',
    entity: 'Rate Card',
    description: 'Pre-defined set of various cost and expenses.',
  },
  // languages: {
  //   label: 'Contact Languages',
  //   value: 'languages',
  //   type: 'languages',
  //   entity: 'Language',
  //   description: 'Language to communicate with contact.'
  // },
  document_templates: {
    label: 'Document Templates',
    value: 'document-template',
    type: 'document_templates',
    entity: 'Document',
    description: 'Pre-defined template for document.',
  },
  email_templates: {
    label: 'Email Templates',
    value: 'email-template',
    type: 'email_templates',
    entity: 'Email',
    description: 'Pre-defined template for email.',
  },
  templates: {
    label: 'Whatsapp Templates',
    value: 'template',
    type: 'templates',
    entity: 'Whatsapp Template',
    description: 'Pre-defined template for whatsapp.',
  },
  default_folders: {
    label: 'Default Folders',
    value: 'default-folder',
    type: 'default_folders',
    entity: 'Folder',
    description: 'Default folders to upload documents.',
  },
  document_types: {
    label: 'Document Types',
    value: 'document-types',
    type: 'document_types',
    entity: 'Document',
    description: 'Required document types.',
  },
  customs_types: {
    label: 'Customs Types',
    value: 'customs-types',
    type: 'customs_types',
    entity: 'Customs Type',
    description: 'List of customs types for shipments.',
  },
  miscellaneous_settings: {
    label: 'Miscellaneous Settings',
    value: 'miscellaneous-settings',
    type: 'miscellaneous_settings',
    entity: 'Setting',
    description: 'Adhoc single entity setting. ',
  },
  config_settings: {
    label: 'Config settings',
    value: 'config-settings',
    type: 'config_settings',
    entity: 'Config Setting',
    description: 'Enable or Disable config setting. ',
  },
}

export const tabs: WorkspaceTab[] = Object.values(tabDefinitions)
