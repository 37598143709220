/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SettingTypeEnum = "config_settings" | "customs_types" | "default_folders" | "document_templates" | "document_types" | "email_templates" | "languages" | "miscellaneous_settings" | "ports" | "rate_cards" | "roles" | "shipment_types" | "shipping_lines" | "templates" | "%future added value";
export type RateCardFieldQueryVariables = {
    type: SettingTypeEnum;
};
export type RateCardFieldQueryResponse = {
    readonly settingList: {
        readonly items: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly slug: string;
                    readonly title: string;
                    readonly keyFields: ReadonlyArray<{
                        readonly key: string;
                        readonly " $fragmentRefs": FragmentRefs<"KeyField_data">;
                    }> | null;
                } | null;
            } | null> | null;
        };
    };
};
export type RateCardFieldQuery = {
    readonly response: RateCardFieldQueryResponse;
    readonly variables: RateCardFieldQueryVariables;
};



/*
query RateCardFieldQuery(
  $type: SettingTypeEnum!
) {
  settingList(type: $type) {
    items {
      edges {
        node {
          slug
          title
          keyFields {
            key
            ...KeyField_data
          }
          id
        }
      }
    }
  }
}

fragment KeyField_data on KeyField {
  label
  value {
    key
    ...SemanticString_data
  }
}

fragment SemanticString_data on SemanticString {
  text
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "type"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "type",
    "variableName": "type"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RateCardFieldQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SettingsList",
        "kind": "LinkedField",
        "name": "settingList",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SettingsItemConnection",
            "kind": "LinkedField",
            "name": "items",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SettingsItemEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SettingsItem",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "KeyField",
                        "kind": "LinkedField",
                        "name": "keyFields",
                        "plural": true,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "KeyField_data"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RateCardFieldQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SettingsList",
        "kind": "LinkedField",
        "name": "settingList",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SettingsItemConnection",
            "kind": "LinkedField",
            "name": "items",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SettingsItemEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SettingsItem",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "KeyField",
                        "kind": "LinkedField",
                        "name": "keyFields",
                        "plural": true,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "label",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SemanticString",
                            "kind": "LinkedField",
                            "name": "value",
                            "plural": true,
                            "selections": [
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "text",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bdc163924910f2a14e92ff6fe5ba0f9d",
    "id": null,
    "metadata": {},
    "name": "RateCardFieldQuery",
    "operationKind": "query",
    "text": "query RateCardFieldQuery(\n  $type: SettingTypeEnum!\n) {\n  settingList(type: $type) {\n    items {\n      edges {\n        node {\n          slug\n          title\n          keyFields {\n            key\n            ...KeyField_data\n          }\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment KeyField_data on KeyField {\n  label\n  value {\n    key\n    ...SemanticString_data\n  }\n}\n\nfragment SemanticString_data on SemanticString {\n  text\n}\n"
  }
};
})();
(node as any).hash = '177aeb6c2378930a07d5b1651845a262';
export default node;
