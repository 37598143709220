/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type MessagesCard_messages = {
    readonly nodes: ReadonlyArray<{
        readonly slug: string;
        readonly " $fragmentRefs": FragmentRefs<"MessageItem_message">;
    } | null> | null;
    readonly " $refType": "MessagesCard_messages";
};
export type MessagesCard_messages$data = MessagesCard_messages;
export type MessagesCard_messages$key = {
    readonly " $data"?: MessagesCard_messages$data;
    readonly " $fragmentRefs": FragmentRefs<"MessagesCard_messages">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MessagesCard_messages",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Message",
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "MessageItem_message"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "MessageConnection",
  "abstractKey": null
};
(node as any).hash = 'a5e794fb3c8a986dc9ba495d61c7870f';
export default node;
