/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type FeedItemListTypeEnum = "all" | "config_settings" | "contacts" | "containers" | "customs_types" | "default_folders" | "document_templates" | "document_types" | "email_templates" | "languages" | "miscellaneous_settings" | "ports" | "rate_cards" | "roles" | "shipment_types" | "shipments" | "shipping_lines" | "templates" | "vessels" | "voyages" | "%future added value";
export type ExternalTimelineQueryVariables = {
    slug?: string | null;
    type: FeedItemListTypeEnum;
    group?: string | null;
    parentAbsoluteSlug?: string | null;
    count?: number | null;
    cursor?: string | null;
};
export type ExternalTimelineQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"ExternalTimeline_queryData">;
};
export type ExternalTimelineQuery = {
    readonly response: ExternalTimelineQueryResponse;
    readonly variables: ExternalTimelineQueryVariables;
};



/*
query ExternalTimelineQuery(
  $slug: String
  $type: FeedItemListTypeEnum!
  $group: String
  $parentAbsoluteSlug: String
  $count: Int
  $cursor: String
) {
  ...ExternalTimeline_queryData
}

fragment ExternalFeedAttachments_data on AttachmentConnection {
  nodes {
    slug
    ...SmallAttachment_attachment
  }
}

fragment ExternalFeedImages_data on ImageConnection {
  edges {
    node {
      url
      shape
      overlay
    }
  }
}

fragment ExternalFeedItem_data on ExternalFeedItem {
  slug
  entity
  title
  titleImage {
    url
  }
  happenedAt
  images {
    edges {
      node {
        url
      }
    }
    ...ExternalFeedImages_data
  }
  contentLines {
    key
    text
  }
  attachments {
    nodes {
      slug
    }
    ...ExternalFeedAttachments_data
  }
  keyFields {
    key
    ...KeyField_data
  }
}

fragment ExternalTimeline_queryData on ApplicationQuery {
  externalFeedItemList(slug: $slug, type: $type, group: $group, parentAbsoluteSlug: $parentAbsoluteSlug) {
    feedItems(first: $count, after: $cursor) {
      edges {
        node {
          slug
          dayLabel
          ...ExternalFeedItem_data
          id
          __typename
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
}

fragment KeyField_data on KeyField {
  label
  value {
    key
    ...SemanticString_data
  }
}

fragment SemanticString_data on SemanticString {
  text
}

fragment SmallAttachment_attachment on Attachment {
  url
  name
  contentType
  previewUrl
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "group"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "parentAbsoluteSlug"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "slug"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "type"
},
v6 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v10 = [
  (v9/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "text",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ExternalTimelineQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ExternalTimeline_queryData"
      }
    ],
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v5/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "ExternalTimelineQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "group",
            "variableName": "group"
          },
          {
            "kind": "Variable",
            "name": "parentAbsoluteSlug",
            "variableName": "parentAbsoluteSlug"
          },
          {
            "kind": "Variable",
            "name": "slug",
            "variableName": "slug"
          },
          {
            "kind": "Variable",
            "name": "type",
            "variableName": "type"
          }
        ],
        "concreteType": "ExternalFeedItemList",
        "kind": "LinkedField",
        "name": "externalFeedItemList",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "ExternalFeedItemConnection",
            "kind": "LinkedField",
            "name": "feedItems",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ExternalFeedItemEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ExternalFeedItem",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "dayLabel",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "entity",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Image",
                        "kind": "LinkedField",
                        "name": "titleImage",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "happenedAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ImageConnection",
                        "kind": "LinkedField",
                        "name": "images",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ImageEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Image",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v8/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "shape",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "overlay",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SemanticString",
                        "kind": "LinkedField",
                        "name": "contentLines",
                        "plural": true,
                        "selections": (v10/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AttachmentConnection",
                        "kind": "LinkedField",
                        "name": "attachments",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Attachment",
                            "kind": "LinkedField",
                            "name": "nodes",
                            "plural": true,
                            "selections": [
                              (v7/*: any*/),
                              (v8/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "contentType",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "previewUrl",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "KeyField",
                        "kind": "LinkedField",
                        "name": "keyFields",
                        "plural": true,
                        "selections": [
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "label",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SemanticString",
                            "kind": "LinkedField",
                            "name": "value",
                            "plural": true,
                            "selections": (v10/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v6/*: any*/),
            "filters": null,
            "handle": "connection",
            "key": "ExternalTimeline_feedItemList_feedItems",
            "kind": "LinkedHandle",
            "name": "feedItems"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "aa743869f8b0aa09b1601049e41c6484",
    "id": null,
    "metadata": {},
    "name": "ExternalTimelineQuery",
    "operationKind": "query",
    "text": "query ExternalTimelineQuery(\n  $slug: String\n  $type: FeedItemListTypeEnum!\n  $group: String\n  $parentAbsoluteSlug: String\n  $count: Int\n  $cursor: String\n) {\n  ...ExternalTimeline_queryData\n}\n\nfragment ExternalFeedAttachments_data on AttachmentConnection {\n  nodes {\n    slug\n    ...SmallAttachment_attachment\n  }\n}\n\nfragment ExternalFeedImages_data on ImageConnection {\n  edges {\n    node {\n      url\n      shape\n      overlay\n    }\n  }\n}\n\nfragment ExternalFeedItem_data on ExternalFeedItem {\n  slug\n  entity\n  title\n  titleImage {\n    url\n  }\n  happenedAt\n  images {\n    edges {\n      node {\n        url\n      }\n    }\n    ...ExternalFeedImages_data\n  }\n  contentLines {\n    key\n    text\n  }\n  attachments {\n    nodes {\n      slug\n    }\n    ...ExternalFeedAttachments_data\n  }\n  keyFields {\n    key\n    ...KeyField_data\n  }\n}\n\nfragment ExternalTimeline_queryData on ApplicationQuery {\n  externalFeedItemList(slug: $slug, type: $type, group: $group, parentAbsoluteSlug: $parentAbsoluteSlug) {\n    feedItems(first: $count, after: $cursor) {\n      edges {\n        node {\n          slug\n          dayLabel\n          ...ExternalFeedItem_data\n          id\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n}\n\nfragment KeyField_data on KeyField {\n  label\n  value {\n    key\n    ...SemanticString_data\n  }\n}\n\nfragment SemanticString_data on SemanticString {\n  text\n}\n\nfragment SmallAttachment_attachment on Attachment {\n  url\n  name\n  contentType\n  previewUrl\n}\n"
  }
};
})();
(node as any).hash = '663274b8776bc01e646b191ab59c6177';
export default node;
