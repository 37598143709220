/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type infoAddDraftShipmentInput = {
    clientMutationId?: string | null;
    containerSlug: string;
    profileImageSlug?: string | null;
    shipmentType: string;
    vin: string;
    description: string;
    weight?: number | null;
    vinEngine?: string | null;
    vinFuelType?: string | null;
    vinBodyClass?: string | null;
    vinOrigin?: string | null;
    linkedContacts?: Array<LinkedContactInput> | null;
    contactCreate?: boolean | null;
    format?: string | null;
    salutation?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    businessPhone?: string | null;
    company?: string | null;
    country?: string | null;
    place?: string | null;
    postcode?: string | null;
    address?: string | null;
    state?: string | null;
    language?: string | null;
    eori?: string | null;
    vat?: string | null;
};
export type LinkedContactInput = {
    role: string;
    contactSlug: string;
};
export type DraftShipmentAddMutationVariables = {
    input: infoAddDraftShipmentInput;
};
export type DraftShipmentAddMutationResponse = {
    readonly infoAddDraftShipment: {
        readonly clientMutationId: string | null;
    } | null;
};
export type DraftShipmentAddMutation = {
    readonly response: DraftShipmentAddMutationResponse;
    readonly variables: DraftShipmentAddMutationVariables;
};



/*
mutation DraftShipmentAddMutation(
  $input: infoAddDraftShipmentInput!
) {
  infoAddDraftShipment(input: $input) {
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "infoAddDraftShipmentPayload",
    "kind": "LinkedField",
    "name": "infoAddDraftShipment",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DraftShipmentAddMutation",
    "selections": (v1/*: any*/),
    "type": "ApplicationCommand",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DraftShipmentAddMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "56fc6b90c5c7b41b7e8181d2d2a3603b",
    "id": null,
    "metadata": {},
    "name": "DraftShipmentAddMutation",
    "operationKind": "mutation",
    "text": "mutation DraftShipmentAddMutation(\n  $input: infoAddDraftShipmentInput!\n) {\n  infoAddDraftShipment(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();
(node as any).hash = '34195f0464cc0378dd41d9a23b7949da';
export default node;
