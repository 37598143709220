/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type AttachmentEntityTypeEnum = "contacts" | "containers" | "shipments" | "%future added value";
export type infoGenerateDocumentInput = {
    clientMutationId?: string | null;
    entityType: AttachmentEntityTypeEnum;
    entitySlug: string;
    templateSlug: string;
    folderSlug: string;
    esignTemplate?: boolean | null;
};
export type GeneratableDocItemMutationVariables = {
    input: infoGenerateDocumentInput;
};
export type GeneratableDocItemMutationResponse = {
    readonly infoGenerateDocument: {
        readonly clientMutationId: string | null;
        readonly shipment: {
            readonly slug: string;
        } | null;
        readonly container: {
            readonly slug: string;
            readonly pickupOrders: ReadonlyArray<{
                readonly attachmentSlug: string;
                readonly name: string;
                readonly isImage: boolean;
            }> | null;
        } | null;
    } | null;
};
export type GeneratableDocItemMutation = {
    readonly response: GeneratableDocItemMutationResponse;
    readonly variables: GeneratableDocItemMutationVariables;
};



/*
mutation GeneratableDocItemMutation(
  $input: infoGenerateDocumentInput!
) {
  infoGenerateDocument(input: $input) {
    clientMutationId
    shipment {
      slug
      id
    }
    container {
      slug
      pickupOrders {
        attachmentSlug
        name
        isImage
        id
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientMutationId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "attachmentSlug",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isImage",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GeneratableDocItemMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "infoGenerateDocumentPayload",
        "kind": "LinkedField",
        "name": "infoGenerateDocument",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Shipment",
            "kind": "LinkedField",
            "name": "shipment",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Container",
            "kind": "LinkedField",
            "name": "container",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "EntityAttachment",
                "kind": "LinkedField",
                "name": "pickupOrders",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationCommand",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GeneratableDocItemMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "infoGenerateDocumentPayload",
        "kind": "LinkedField",
        "name": "infoGenerateDocument",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Shipment",
            "kind": "LinkedField",
            "name": "shipment",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Container",
            "kind": "LinkedField",
            "name": "container",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "EntityAttachment",
                "kind": "LinkedField",
                "name": "pickupOrders",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a5336e97ecc39c344740254b588945b9",
    "id": null,
    "metadata": {},
    "name": "GeneratableDocItemMutation",
    "operationKind": "mutation",
    "text": "mutation GeneratableDocItemMutation(\n  $input: infoGenerateDocumentInput!\n) {\n  infoGenerateDocument(input: $input) {\n    clientMutationId\n    shipment {\n      slug\n      id\n    }\n    container {\n      slug\n      pickupOrders {\n        attachmentSlug\n        name\n        isImage\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '31ff1486a33e6f15692025b5ac479595';
export default node;
