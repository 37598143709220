import { Card, Typography } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'
import { NoteEntityTypeEnum } from '../__generated__/NoteTabQuery.graphql'
import { graphql } from 'babel-plugin-relay/macro'
import { useLazyLoadQuery } from 'react-relay/hooks'
import { useDateTimeDisplay } from '../hooks/timeZoneDisplay'
import { SharedNoteListQuery } from '../__generated__/SharedNoteListQuery.graphql'

interface SharedNoteListProps {
  entitySlug: string
  entityType: NoteEntityTypeEnum
}

const NotesContainer = styled.div`
  display: grid;
  grid-column-template: auto auto;
  grid-gap: 8px;
  grid-template-columns: repeat(2, 1fr);
`
const Note = styled(Card)`
  width: 100%;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const NoteContent = styled(Typography)`
  white-space: pre-wrap;
`
const NoteFooter = styled.div`
  margin-top: 4px;
  display: flex;
  justify-content: space-between;
`
const NoteFooterText = styled(Typography)`
  flex: 1;
  text-align: right;
`

const SharedNoteList: React.FC<SharedNoteListProps> & { Skeleton: React.FC } = ({ entityType, entitySlug }) => {
  const data = useLazyLoadQuery<SharedNoteListQuery>(query, { entityType: entityType, entitySlug: entitySlug })

  return (
    <>
      {data.externalSharedNoteList.length > 0 && (
        <>
          <hr></hr>
          <br></br>
          <Typography variant='h6'>Notes shared with you</Typography>
          <br></br>
        </>
      )}
      <NotesContainer>
        {data.externalSharedNoteList.map(
          (note) =>
            note && (
              <>
                <Note key={note.slug} variant='outlined'>
                  <NoteContent variant='body1'>{note.content}</NoteContent>
                  <NoteFooter>
                    <NoteFooterText variant='caption' color='textSecondary'>
                      {note.addedBy.username} {useDateTimeDisplay(note.addedAt)}
                    </NoteFooterText>
                  </NoteFooter>
                </Note>
              </>
            )
        )}
      </NotesContainer>
    </>
  )
}

const query = graphql`
  query SharedNoteListQuery($entityType: NoteEntityTypeEnum!, $entitySlug: String!) {
    externalSharedNoteList(entityType: $entityType, entitySlug: $entitySlug) {
      slug
      content
      addedAt
      addedBy {
        username
      }
    }
  }
`

SharedNoteList.Skeleton = () => <>Loading...</>

export { SharedNoteList }
