import React, { Suspense } from 'react'
import OdysseyDialog from './OdysseyDialog'
import { Checkbox, DialogTitle, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { StyledDialogContent } from './File/FolderRoleVisibility'
import { graphql } from 'babel-plugin-relay/macro'
import { ShareNoteQuery } from '../__generated__/ShareNoteQuery.graphql'
import { useLazyLoadQuery } from 'react-relay/hooks'
import NiceFormik from './Form/NiceFormik'
import useNiceMutation from '../mutations/useNiceMutation'
import { ShareNoteMutation } from '../__generated__/ShareNoteMutation.graphql'
import { Alert } from '@material-ui/lab'
import DialogFormActionBar from './Form/DialogFormActionBar'
import styled from 'styled-components'
import { useSnackbar } from 'notistack'

interface Props {
  shareNoteOpen: boolean
  onClose: () => void
  slug: string
}
const StyledOdysseyDialog = styled(OdysseyDialog)`
  max-width: 640px;
  margin: auto;
`

const ShareNote: React.FC<Props> = (props) => {
  return (
    <>
      <StyledOdysseyDialog open={props.shareNoteOpen} fullWidth scroll='paper' onClose={props.onClose}>
        <Suspense fallback={<>Loading...</>}>
          <ShareNoteContent {...props}></ShareNoteContent>
        </Suspense>
      </StyledOdysseyDialog>
    </>
  )
}

const ShareNoteContent: React.FC<Props> = ({ onClose, slug }) => {
  const data = useLazyLoadQuery<ShareNoteQuery>(query, { slug: slug })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [commitShareNote, loading, shareNoteError] = useNiceMutation<ShareNoteMutation>(mutation)
  const { enqueueSnackbar } = useSnackbar()

  return (
    <>
      <NiceFormik
        initialValues={{ roleVisibilities: data.showNote.roleVisibilities }}
        onSubmit={(values, actions) => {
          commitShareNote({
            variables: {
              input: {
                slug: slug,
                entitySlug: data.showNote.entitySlug,
                entityType: data.showNote.entityType,
                roles: values.roleVisibilities.filter((roleVisibility) => roleVisibility.visible).map((roleVisibility) => roleVisibility.role),
              },
            },
            onCompleted: (_, errors) => {
              actions.setSubmitting(false)
              if (!errors) {
                enqueueSnackbar('Note shared Successfully', { variant: 'success' })
                onClose()
              }
            },
          })
        }}
      >
        {({ values, setFieldValue, submitForm }) => (
          <>
            <DialogTitle>Share note to external users</DialogTitle>
            {shareNoteError && <Alert severity='error'>{shareNoteError}</Alert>}
            <StyledDialogContent dividers>
              <List>
                {values.roleVisibilities.map((roleVisibility, idx) => (
                  <ListItem
                    key={roleVisibility.role}
                    role={undefined}
                    button
                    onClick={() => setFieldValue(`roleVisibilities[${idx}].visible`, !roleVisibility.visible)}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge='start'
                        checked={roleVisibility.visible}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': `${roleVisibility.role}-${idx}` }}
                      />
                    </ListItemIcon>
                    <ListItemText id={`${roleVisibility.role}-${idx}`} primary={`${roleVisibility.roleTitle}`} />
                  </ListItem>
                ))}
              </List>
            </StyledDialogContent>
            <DialogFormActionBar
              onCancel={onClose}
              onSubmit={() => {
                submitForm()
              }}
              cancelCta='Cancel'
              saveCta='Share'
            />
          </>
        )}
      </NiceFormik>
    </>
  )
}

const query = graphql`
  query ShareNoteQuery($slug: String!) {
    showNote(slug: $slug) {
      slug
      entitySlug
      entityType
      roleVisibilities {
        role
        roleShortTitle
        roleTitle
        visible
      }
    }
  }
`

const mutation = graphql`
  mutation ShareNoteMutation($input: infoShareNoteInput!) {
    infoShareNote(input: $input) {
      clientMutationId
    }
  }
`
export default ShareNote
