import {
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { graphql } from 'babel-plugin-relay/macro'
import React, { Suspense, useContext, useState } from 'react'
import styled from 'styled-components'
import Field from '../../components/Field'
import { AddNotes } from '../../components/Notes'
import ResponsiveGrid from '../../components/ResponsiveGrid'
import Timeline from '../../components/Timeline/Timeline'
import { media } from '../../theme'
import { HomeContentQuery } from '../../__generated__/HomeContentQuery.graphql'
import ItemListCard from '../../components/ItemListCard'
import ContactRateCard from './ContactRateCard'
import FoldersCard from '../../components/File/FoldersCard'
import EmailsCard from '../../components/Email/EmailsCard'
import useLazyLoadQueryWithSubscription from '../../hooks/useLazyLoadQueryWithSubscription'
import KeyValueField from '../../components/KeyValueField'
import Button from '../../components/Button'
import SessionContext from '../../SessionContext'
import ConfirmBox from '../../components/ConfirmBox'
import useNiceMutation from '../../mutations/useNiceMutation'
import { HomeContentDisableTwoFactorAuthMutation } from '../../__generated__/HomeContentDisableTwoFactorAuthMutation.graphql'
import { useSnackbar } from 'notistack'
import LoadingDots from '../../components/LoadingDots'
import { HomeContentAddIpAddressesMutation } from '../../__generated__/HomeContentAddIpAddressesMutation.graphql'
import OdysseyDialog from '../../components/OdysseyDialog'
import { HomeContentConfigSettingsMutation } from '../../__generated__/HomeContentConfigSettingsMutation.graphql'

interface Props {
  slug: string
}

const BorderedCard = styled.div`
  border: 1px solid ${(props) => props.theme.customPalette.border.card};
  border-radius: 8px;
  margin-bottom: 16px;
  padding: 16px;

  :last-child {
    margin-bottom: unset;
  }
`

const DetailsContainer = styled.div`
  margin: 4px 0;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 8px;

  ${media.medium`
    grid-template-columns: repeat(3, 1fr);
  `}

  ${media.small`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${media.mobile`
    grid-template-columns: repeat(1, 1fr);
  `}
`

const LongDetailsContainer = styled.div`
  margin: 4px 0;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 8px;

  ${media.medium`
    grid-template-columns: repeat(1, 1fr);
  `}

  ${media.small`
    grid-template-columns: repeat(1, 1fr);
  `}
`
const Root = styled(Paper)`
  margin-top: 5px;
  padding: 5px;
`

const Container = styled.div`
  width: 100%;
  margin: 2px 0;
  overflow: hidden;
`

const Label = styled(Typography)`
  font-size: 12px;
`

const Value = styled(Typography)`
  font-weight: 500;
  box-sizing: content-box;
  white-space: nowrap;
  overflow: hidden;
  line-height: 24px;
`

const HomeContent: React.FC<Props> & { Skeleton: React.FC } = ({ slug }) => {
  const { user } = useContext(SessionContext)
  const { enqueueSnackbar } = useSnackbar()
  const [commit, loading] = useNiceMutation<HomeContentDisableTwoFactorAuthMutation>(disableTwoFactorAuth)
  const [commitIpAddress, onFlight] = useNiceMutation<HomeContentAddIpAddressesMutation>(addIpAddresses)
  const [commitConfigSetting, loadingConfig] = useNiceMutation<HomeContentConfigSettingsMutation>(configSettingsMutation)

  const isSuperAdmin = Boolean(user?.contact?.displayRoles.find((role) => role.value === 'super-admin'))
  const queryData = useLazyLoadQueryWithSubscription<HomeContentQuery>(
    query,
    { slug: slug },
    { subscriptionOptions: { entityType: 'contacts', entitySlug: slug } }
  )
  const { contactShow: contact } = queryData
  const [openConfirm, setOpenConfirm] = useState<boolean>(false)
  const [ipAddresses, setIpAddresses] = useState<string>(contact.allowIpAddresses as string | '')
  const [configSlug, setConfigSlug] = useState<string>('')
  const [openPopup, setOpenPopup] = useState<boolean>(false)
  const [apply, setApply] = useState<boolean>(false)
  const useStyles = makeStyles({
    success: {
      color: 'green',
    },
  })
  const classes = useStyles()

  const onConfirm = () => {
    commit({
      variables: { input: { username: contact.slug, workspace: user?.defaultWorkspace as string } },
      onCompleted: (_, err) => {
        if (!err) {
          enqueueSnackbar('Two factor authentication disable successfully', { variant: 'success' })
        }
      },
    })
  }

  const hanldeIpAddress = () => {
    commitIpAddress({
      variables: { input: { username: contact.slug, ipAddresses: ipAddresses } },
      onCompleted: (_, err) => {
        if (!err) {
          enqueueSnackbar('Ip Address added successfully', { variant: 'success' })
        }
      },
    })
  }

  const financeDetails = () => (
    <BorderedCard key={1}>
      <Typography variant='h6'>Finance</Typography>
      <DetailsContainer>
        <Field label='EORI' value={contact.eori} />
        <Field label='Lex Office Id' value={contact.lexOfficeId} />
        <Field label='VAT' value={contact.vat} />
        {contact.roles.includes('customer') && (
          <>
            <KeyValueField label='Vat Warehousing' value={contact.vatWarehousing.toString()} />
            <KeyValueField label='Vat Revenue' value={contact.vatRevenues.toString()} />
          </>
        )}
      </DetailsContainer>

      {contact.roles.includes('warehouse') && (
        <DetailsContainer>
          <Field label='Customs Office' value={contact.customsOffice} />
          <Field label='Free warehousing days' value={contact.freeWarehousingDays} />
          <Field label='Cost warehousing per day' value={contact.costWarehousingPerDay} />
          <Field label='Cost Shipment' value={contact.costShipmentDischarge} />
          <Field label='Expenses' value={contact.expenses} />
        </DetailsContainer>
      )}
    </BorderedCard>
  )

  const miscellaneousSettings = () => (
    <BorderedCard key={1}>
      <Typography variant='h6'>Miscellaneous Settings</Typography>
      <DetailsContainer>
        <Container>
          <Label variant='caption' color='textSecondary'>
            Sync With Emory
          </Label>
          <Value variant='body1'>
            {contact.syncWithEmory === true ? <Typography className={classes.success}>✓</Typography> : <Typography color='error'>✗</Typography>}
          </Value>
        </Container>
        <Container>
          <Label variant='caption' color='textSecondary'>
            ATB for Customs
          </Label>
          <Value variant='body1'>
            {contact.atbForCustoms === true ? <Typography className={classes.success}>✓</Typography> : <Typography color='error'>✗</Typography>}
          </Value>
        </Container>
      </DetailsContainer>
    </BorderedCard>
  )

  const configSettings = () => (
    <BorderedCard key={1}>
      <Typography variant='h6'>Config Settings</Typography>
      {contact.configSettings &&
        contact.configSettings.map((setting, idx) => (
          <Root variant='outlined' key={idx}>
            <DetailsContainer>
              <Field label='Label' value={setting.label} />
              <Field label='Description' value={setting.description} />
              <Field label='Email Template Name' value={setting.emailTemplateName} />
              <Field label='Applied' value={setting.apply ? 'Yes' : 'No'} />
              <Button onClick={() => handleConfigPopupOpen(setting.slug, setting.apply)}>Edit</Button>
            </DetailsContainer>
          </Root>
        ))}
    </BorderedCard>
  )

  const handleConfigPopupOpen = (slug: string, apply: boolean) => {
    setConfigSlug(slug)
    setOpenPopup(true)
    setApply(apply)
  }

  const handleConfigPopupClose = () => {
    setConfigSlug('')
    setOpenPopup(false)
    setApply(false)
  }

  const handleConfigSettingCommit = () => {
    commitConfigSetting({
      variables: { input: { slug: contact.slug, configSettingSlug: configSlug, apply: apply } },
      onCompleted: (_, err) => {
        if (!err) {
          enqueueSnackbar('Config setting updated successfully', { variant: 'success' })
          handleConfigPopupClose()
        }
      },
    })
  }

  const editConfigSetting = () => {
    const info = contact.configSettings.find((setting) => setting.slug === configSlug)

    return (
      <>
        <OdysseyDialog open={openPopup} onClose={handleConfigPopupClose}>
          <DialogTitle>Edit {info?.label}</DialogTitle>
          <DialogContent>
            <Container>
              <Label variant='caption' color='textSecondary'>
                Description
              </Label>
              <Value variant='body1'>{info?.description || '--'}</Value>
              <br></br>
              <Label variant='caption' color='textSecondary'>
                Email Template Name
              </Label>
              <Value variant='body1'>{info?.emailTemplateName || '--'}</Value>
              <br></br>
              <FormGroup>
                <FormControlLabel control={<Checkbox checked={apply} onChange={(event) => setApply(event.target.checked)} />} label='Apply' />
              </FormGroup>
            </Container>
          </DialogContent>
          <DialogActions>
            <Button variant='contained'>Cancel</Button>
            <Button variant='outlined' disabled={apply == info?.apply} onClick={() => handleConfigSettingCommit()}>
              {loadingConfig ? <LoadingDots /> : 'Save'}
            </Button>
          </DialogActions>
        </OdysseyDialog>
      </>
    )
  }

  return (
    <ResponsiveGrid
      type='show-layout'
      highlight={[
        <BorderedCard key={0}>
          <Typography variant='h6'>Contact Details</Typography>
          <DetailsContainer>
            <Field label='Contact Form' value={contact.format} />
            <Field label='Company' value={contact.company} />
            <Field label='Address' value={contact.address} />
          </DetailsContainer>
          <DetailsContainer>
            <Field label='Postcode' value={contact.postcode} />
            <Field label='City' value={contact.place} />
            <Field label='State' value={contact.state} />
            <Field label='Position' value={contact.position} />
            <Field label='Business Phone' value={contact.businessPhone} />
            <Field label='Mobile Phone' value={contact.mobilePhone} />
            <Field label='Private Phone' value={contact.privatePhone} />
            <Field label='Fax Phone' value={contact.faxPhone} />
            {contact.contactType == 'internal' && <Field label='Zoll Contact Number' value={contact.zollContactNumber} />}
            <Field label='Language' value={contact.language} />
            <Field label='Country' value={contact.country} />
            <Field label='Website' value={contact.website} />
            <Field label='Blocked' value={contact.blocked ? 'Yes' : 'No'} />
            {contact.contactType == 'internal' && <Field label='Zoll Phone' value={contact.zollPhone} />}
          </DetailsContainer>
          <LongDetailsContainer>
            <Field label='Secondary Emails' value={contact.secondaryEmails?.join(' | ')} />
          </LongDetailsContainer>
          {isSuperAdmin && contact.registrationStatus == 'registered' && (
            <>
              <DetailsContainer>
                {contact.twoFactorAuthActivated ? (
                  <>
                    {openConfirm && (
                      <ConfirmBox
                        open={openConfirm}
                        onClose={() => setOpenConfirm(false)}
                        title='Are you sure you want to Deactivate 2F Auth?'
                        onConfirm={onConfirm}
                      />
                    )}
                    <Typography variant='h6'>2FA activated</Typography>
                    <Button variant='contained' onClick={() => setOpenConfirm(true)}>
                      {loading ? <LoadingDots /> : 'Deactivate'}
                    </Button>
                  </>
                ) : (
                  <Typography variant='h6'>2FA not activated</Typography>
                )}
              </DetailsContainer>
              <br></br>
              <DetailsContainer>
                <TextField
                  variant='outlined'
                  value={ipAddresses}
                  onChange={(e) => setIpAddresses(e.target.value)}
                  label='Ip adderess'
                  type='text'
                />
                <Button variant='contained' onClick={() => hanldeIpAddress()}>
                  {onFlight ? <LoadingDots /> : ' Allowed IP addresses'}
                </Button>
              </DetailsContainer>
            </>
          )}
        </BorderedCard>,
        <React.Fragment key='finance-details'>{contact.contactType !== 'internal' && editConfigSetting()}</React.Fragment>,
        <React.Fragment key='finance-details'>{contact.contactType !== 'internal' && financeDetails()}</React.Fragment>,
        <React.Fragment key='finance-details'>
          {contact.contactType !== 'internal' && contact.roles.includes('warehouse') && miscellaneousSettings()}
        </React.Fragment>,
        <React.Fragment key='config-settings'>{contact.contactType !== 'internal' && configSettings()}</React.Fragment>,
        <React.Fragment key='rate-card'>
          {contact.contactType !== 'internal' && contact.rateCardItem && <ContactRateCard rateCardItem={contact.rateCardItem} />}
        </React.Fragment>,
        <React.Fragment key='folders'>
          <br></br>
          <FoldersCard
            key='uploads'
            folderType='documents'
            folders={contact.folders}
            title='Documents'
            entityType='contacts'
            entitySlug={contact.slug}
          />
        </React.Fragment>,
        <React.Fragment key='emails'>
          <EmailsCard key='emails' entityType='contacts' entitySlug={contact.slug} emails={contact.emails} />
        </React.Fragment>,
      ]}
      left={[
        <Suspense key='containers-card' fallback={<ItemListCard.Skeleton />}>
          <ItemListCard type='containers' contactSlug={slug} title='Containers' />
        </Suspense>,
      ]}
      middle={[
        <Suspense key='shipments-card' fallback={<ItemListCard.Skeleton />}>
          <ItemListCard type='shipments' contactSlug={slug} title='Shipments' />
        </Suspense>,
      ]}
      right={[
        <Suspense key='notes' fallback={<AddNotes.Skeleton />}>
          <AddNotes entitySlug={contact.slug} entityType='contacts' />
        </Suspense>,
        <Suspense key={5} fallback={<Timeline.Skeleton />}>
          <Timeline slug={contact.slug} type='contacts' />
        </Suspense>,
      ]}
    />
  )
}

const query = graphql`
  query HomeContentQuery($slug: String!) {
    contactShow(slug: $slug) {
      slug
      name
      roles
      contactType
      businessPhone
      mobilePhone
      privatePhone
      faxPhone
      zollContactNumber
      zollPhone

      format
      company
      position

      secondaryEmails
      country
      state
      place
      postcode
      language

      address

      eori
      lexOfficeId
      vat
      vatWarehousing
      vatRevenues
      website
      blocked
      twoFactorAuthActivated
      registrationStatus

      customsOffice
      freeWarehousingDays
      costWarehousingPerDay
      costShipmentDischarge
      expenses
      allowIpAddresses
      syncWithEmory
      atbForCustoms
      configSettings {
        label
        description
        apply
        emailTemplateName
        slug
      }

      rateCardItem {
        ...ContactRateCard_rateCardItem
      }

      shipmentItems {
        slug
        ...ShowPageListItem_listItem
      }

      containerItems {
        slug
        ...ShowPageListItem_listItem
      }

      folders {
        ...FoldersCard_folders
      }

      emails {
        ...EmailsCard_emails
      }
    }
  }
`

const disableTwoFactorAuth = graphql`
  mutation HomeContentDisableTwoFactorAuthMutation($input: infoDisableTwoFactorAuthenticationInput!) {
    infoDisableTwoFactorAuthentication(input: $input) {
      clientMutationId
    }
  }
`

const addIpAddresses = graphql`
  mutation HomeContentAddIpAddressesMutation($input: infoAddIpAddressInput!) {
    infoAddIpAddress(input: $input) {
      clientMutationId
    }
  }
`
const configSettingsMutation = graphql`
  mutation HomeContentConfigSettingsMutation($input: infoUpdateContactConfigSettingInput!) {
    infoUpdateContactConfigSetting(input: $input) {
      clientMutationId
    }
  }
`

HomeContent.Skeleton = () => <>Loading...</>

export default HomeContent
