import { Button, DialogActions, DialogContent, DialogTitle, Snackbar, TextField } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { graphql } from 'babel-plugin-relay/macro'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import styled from 'styled-components'
import useNiceMutation from '../../mutations/useNiceMutation'
import { GenerateTokenDialogMutation } from '../../__generated__/GenerateTokenDialogMutation.graphql'
import OdysseyDialog from '../../components/OdysseyDialog'

interface Props {
  slug: string | null
  open: boolean
  onClose: () => void
}

const TokenInput = styled(TextField)`
  margin-bottom: 8px;
`

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const GenerateTokenDialog: React.FC<Props> = ({ slug, open, onClose }) => {
  const [status, setStatus] = useState<string | null>(null)
  const [token, setToken] = useState<string | null>(null)
  const [commit_pass] = useNiceMutation<GenerateTokenDialogMutation>(password_mutation)
  const { enqueueSnackbar } = useSnackbar()

  const clearState = () => {
    setStatus(null)
  }

  console.log(slug)

  const generateToken = () => {
    commit_pass({
      variables: { input: { slug: slug } },
      onCompleted: (
        {
          infoGenerateToken: {
            user: { accessToken },
          },
        },
        err
      ) => {
        if (!err) {
          clearState()
          setStatus('success')
          enqueueSnackbar('Password updated successfully', { variant: 'success' })
          setToken(accessToken)
        }
      },
      onError: (err) => {
        setStatus(`Failed to generate token: ${err}`)
      },
    })
  }

  return (
    <>
      <OdysseyDialog
        fullWidth
        open={open}
        maxWidth='xs'
        onClose={() => {
          clearState()
          onClose()
        }}
      >
        <DialogTitle>Generated Token</DialogTitle>
        <DialogContent>
          <FormContainer>
            <p>Token</p>
            <TokenInput required variant='outlined' value={token || 'N/A'} />
            {token && (
              <p>
                **Please note down your API Key, it will only be displayed once. If you lose this key, you can generate a new key using this
                form.
              </p>
            )}
          </FormContainer>
        </DialogContent>
        <DialogActions>
          <Button
            variant='text'
            onClick={() => {
              clearState()
              onClose()
            }}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              generateToken()
            }}
          >
            Generate
          </Button>
        </DialogActions>
      </OdysseyDialog>
      <Snackbar
        open={!!status && status !== 'success'}
        onClose={() => setStatus(null)}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert severity='error'>{status}</Alert>
      </Snackbar>
      <Snackbar
        open={!!status && status === 'success'}
        onClose={() => setStatus(null)}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert severity='success'>Password changed successfully!</Alert>
      </Snackbar>
    </>
  )
}
const password_mutation = graphql`
  mutation GenerateTokenDialogMutation($input: infoGenerateTokenInput!) {
    infoGenerateToken(input: $input) {
      clientMutationId
      user {
        accessToken
      }
    }
  }
`
