/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type StageStatusEnum = "active" | "completed" | "incomplete" | "%future added value";
export type ListPageItem_listItem = {
    readonly slug: string;
    readonly title: string;
    readonly isArchived: boolean;
    readonly image: {
        readonly " $fragmentRefs": FragmentRefs<"ItemImage_data">;
    } | null;
    readonly path: string | null;
    readonly statuses: ReadonlyArray<{
        readonly key: string;
        readonly customsAgent: string | null;
        readonly value: {
            readonly " $fragmentRefs": FragmentRefs<"SemanticString_data">;
        };
    }> | null;
    readonly stages: ReadonlyArray<{
        readonly key: string;
        readonly status: StageStatusEnum;
        readonly label: string;
        readonly value: string;
    }> | null;
    readonly badges: ReadonlyArray<{
        readonly key: string;
        readonly " $fragmentRefs": FragmentRefs<"Badge_data">;
    }> | null;
    readonly actions: ReadonlyArray<{
        readonly key: string;
        readonly " $fragmentRefs": FragmentRefs<"Action_data">;
    }> | null;
    readonly lines: ReadonlyArray<{
        readonly key: string;
        readonly " $fragmentRefs": FragmentRefs<"SemanticLine_data">;
    }> | null;
    readonly keyFields: ReadonlyArray<{
        readonly key: string;
        readonly " $fragmentRefs": FragmentRefs<"KeyField_data">;
    }> | null;
    readonly linkSets: ReadonlyArray<{
        readonly key: string;
        readonly " $fragmentRefs": FragmentRefs<"LinkSet_data">;
    }> | null;
    readonly stickyNoteContent: string | null;
    readonly " $refType": "ListPageItem_listItem";
};
export type ListPageItem_listItem$data = ListPageItem_listItem;
export type ListPageItem_listItem$key = {
    readonly " $data"?: ListPageItem_listItem$data;
    readonly " $fragmentRefs": FragmentRefs<"ListPageItem_listItem">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ListPageItem_listItem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isArchived",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ItemImage_data"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "path",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Status",
      "kind": "LinkedField",
      "name": "statuses",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customsAgent",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SemanticString",
          "kind": "LinkedField",
          "name": "value",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SemanticString_data"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Stage",
      "kind": "LinkedField",
      "name": "stages",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Badge",
      "kind": "LinkedField",
      "name": "badges",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Badge_data"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Action",
      "kind": "LinkedField",
      "name": "actions",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Action_data"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SemanticLine",
      "kind": "LinkedField",
      "name": "lines",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SemanticLine_data"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "KeyField",
      "kind": "LinkedField",
      "name": "keyFields",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "KeyField_data"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LinkSet",
      "kind": "LinkedField",
      "name": "linkSets",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "LinkSet_data"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "stickyNoteContent",
      "storageKey": null
    }
  ],
  "type": "ListItem",
  "abstractKey": null
};
})();
(node as any).hash = 'd85ac52dc5d75e48e0f56d775f6100c6';
export default node;
