/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type NoteEntityTypeEnum = "contacts" | "containers" | "shipments" | "vessels" | "voyages" | "%future added value";
export type SharedNoteListQueryVariables = {
    entityType: NoteEntityTypeEnum;
    entitySlug: string;
};
export type SharedNoteListQueryResponse = {
    readonly externalSharedNoteList: ReadonlyArray<{
        readonly slug: string;
        readonly content: string;
        readonly addedAt: string;
        readonly addedBy: {
            readonly username: string;
        };
    }>;
};
export type SharedNoteListQuery = {
    readonly response: SharedNoteListQueryResponse;
    readonly variables: SharedNoteListQueryVariables;
};



/*
query SharedNoteListQuery(
  $entityType: NoteEntityTypeEnum!
  $entitySlug: String!
) {
  externalSharedNoteList(entityType: $entityType, entitySlug: $entitySlug) {
    slug
    content
    addedAt
    addedBy {
      username
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "entitySlug"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "entityType"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "entitySlug",
        "variableName": "entitySlug"
      },
      {
        "kind": "Variable",
        "name": "entityType",
        "variableName": "entityType"
      }
    ],
    "concreteType": "Note",
    "kind": "LinkedField",
    "name": "externalSharedNoteList",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "slug",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "content",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "addedAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "addedBy",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SharedNoteListQuery",
    "selections": (v2/*: any*/),
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "SharedNoteListQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "5d812d81e6af9bc8553980624035682f",
    "id": null,
    "metadata": {},
    "name": "SharedNoteListQuery",
    "operationKind": "query",
    "text": "query SharedNoteListQuery(\n  $entityType: NoteEntityTypeEnum!\n  $entitySlug: String!\n) {\n  externalSharedNoteList(entityType: $entityType, entitySlug: $entitySlug) {\n    slug\n    content\n    addedAt\n    addedBy {\n      username\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '80470f181a3bf0b603fcd349f19eb1e2';
export default node;
