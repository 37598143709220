import { Typography, Paper, Chip, Tooltip } from '@material-ui/core'
import { graphql } from 'babel-plugin-relay/macro'
import React from 'react'
import { useFragment } from 'react-relay/hooks'
import styled from 'styled-components'
import { EntityAttachment_attachment$key } from '../../__generated__/EntityAttachment_attachment.graphql'
import { previewForAttachment } from './helpers'

interface Props {
  attachment: EntityAttachment_attachment$key
  variant?: 'block' | 'chip'
  style?: React.CSSProperties
  onPreview?: () => void
}

const RootPaper = styled(Paper).attrs({ variant: 'outlined' })`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  height: 100%;

  &:hover {
    filter: brightness(0.95);
  }
`

const DocumentTypeTag = styled.div`
  position: absolute;
  right: 0px;
  top: -10px;
  background-color: ${(props) => props.theme.palette.grey[300]};
  padding: 0 8px;
  border-radius: 16px;
  max-width: 90%;
`

const SignedStatus = styled.div<{ $status?: string }>`
  right: 0;
  background-color: ${(props) => {
    const statusColors: any = {
      Requested: '#ffd98e',
      Signed: '#bdf0d1',
      Rejected: '#E57373',
      Replaced: '#E23433',
    }
    const status = props.$status || ''
    return statusColors[status] || props.theme.palette.grey[300]
  }};
  padding: 0 8px;
  border-radius: 16px;
  max-width: 90%;
`
const InvoiceStatus = styled.div`
  background-color: ${(props) => props.theme.palette.grey[300]};
  padding: 0 8px;
  border-radius: 16px;
  max-width: 90%;
`

const DocumentTypeTagTypography = styled(Typography).attrs({ variant: 'caption' })`
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const PreviewContainer = styled.div`
  width: 100%;
  height: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Preview = styled.img<{ $iconPreview?: boolean; $isChip?: boolean }>`
  width: ${(props) => (props.$iconPreview ? '40px' : '100%')};
  height: ${(props) => (props.$iconPreview ? '40px' : '100%')};
  object-fit: cover;
`

const AttachmentName = styled(Typography)`
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  text-overflow: ellipsis;
  width: 90%;
  padding: 4px;
`

const EntityAttachment: React.FC<Props> = ({ attachment: data, variant = 'block', style, onPreview }) => {
  const attachment = useFragment(fragment, data)
  const { previewUrl, isIconPreview } = previewForAttachment(attachment)

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.ctrlKey) {
      window.open(attachment.url)
    } else {
      onPreview && onPreview()
    }
  }

  return (
    <>
      {variant === 'chip' ? (
        <Chip avatar={<Preview $isChip={true} src={previewUrl} $iconPreview={isIconPreview} />} label={attachment.name} onClick={handleClick} />
      ) : (
        <RootPaper style={style} title={attachment.name} onClick={handleClick}>
          <PreviewContainer>
            <Preview src={previewUrl} $iconPreview={isIconPreview} $isChip={false} />
          </PreviewContainer>
          <AttachmentName variant='body2'>{attachment.name}</AttachmentName>
          {attachment.signedStatus && (
            <SignedStatus $status={attachment.signedStatus as string}>
              {attachment?.rejectReason ? (
                <Tooltip title={attachment?.rejectReason as string}>
                  <DocumentTypeTagTypography>{attachment.signedStatus}</DocumentTypeTagTypography>
                </Tooltip>
              ) : (
                <DocumentTypeTagTypography>{attachment.signedStatus}</DocumentTypeTagTypography>
              )}
            </SignedStatus>
          )}
          {attachment.documentType && (
            <>
              <DocumentTypeTag>
                <DocumentTypeTagTypography>{attachment.documentType}</DocumentTypeTagTypography>
              </DocumentTypeTag>
            </>
          )}
          {attachment.draftTicketStatus && (
            <DocumentTypeTag>
              <DocumentTypeTagTypography>{attachment.draftTicketStatus}</DocumentTypeTagTypography>
            </DocumentTypeTag>
          )}
          {attachment.lexOfficeInvoiceStatus && (
            <InvoiceStatus>
              <DocumentTypeTagTypography>{attachment.lexOfficeInvoiceStatus}</DocumentTypeTagTypography>
            </InvoiceStatus>
          )}
        </RootPaper>
      )}
    </>
  )
}

const fragment = graphql`
  fragment EntityAttachment_attachment on EntityAttachment {
    url
    downloadUrl
    name
    contentType
    previewUrl
    documentType
    draftTicketStatus
    lexOfficeInvoiceStatus
    signedStatus
    rejectReason
  }
`

export default EntityAttachment
