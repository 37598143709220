import { graphql } from 'babel-plugin-relay/macro'
import React, { useContext } from 'react'
import { useLazyLoadQuery } from 'react-relay/hooks'
import FormActionBar from '../../components/Form/FormActionBar'
import NiceFormik from '../../components/Form/NiceFormik'
import ContactForm from '../../forms/Contact/ContactForm'
import useNiceMutation from '../../mutations/useNiceMutation'
import { ContactEditFormQuery } from '../../__generated__/ContactEditFormQuery.graphql'
import { ContactEditMutation } from '../../__generated__/ContactEditMutation.graphql'
import FormContainer from '../../forms/FormContainer'
import SessionContext from '../../SessionContext'
import { FormValues } from './ContactAdd'

interface Props {
  slug: string
  onClose: () => void
}

const ContactEdit: React.FC<Props> & { Skeleton: React.FC } = ({ slug, onClose }) => {
  const data = useLazyLoadQuery<ContactEditFormQuery>(query, { slug: slug })
  const { user, reloadSession } = useContext(SessionContext)
  const fields = data.infoUpdateContactForm.formSchema.fieldSet
  const fieldMaster = Object.fromEntries(fields.map((field) => [field.name, field]))

  const [commit] = useNiceMutation<ContactEditMutation>(mutation)

  return (
    <>
      <NiceFormik
        initialValues={data.infoUpdateContactForm.initialValue || { roles: [], address: '', businessPhone: '', place: '' }}
        validate={(values) => {
          const errors: Partial<FormValues> = {}
          if (!(values.roles as string[]).includes('transporter') && !values.address) {
            errors.address = 'Address is a required field'
          }
          if (!(values.roles as string[]).includes('transporter') && !values.place) {
            errors.place = 'City is a required field'
          }

          if ((values.roles as string[]).includes('transporter') && !values.businessPhone) {
            errors.businessPhone = 'businessPhone is a required field'
          }
          return errors
        }}
        onSubmit={(values, actions) => {
          commit({
            variables: {
              input: values as any,
            },
            onError: () => actions.setSubmitting(false),
            onCompleted: (_res, errors) => {
              actions.setSubmitting(false)
              if (!errors) {
                onClose()
                if (slug === user?.username) {
                  reloadSession()
                }
              }
            },
          })
        }}
        formSchema={fields}
      >
        {({ submitForm, values }) => (
          <FormContainer>
            <ContactForm fieldMaster={fieldMaster} mode='edit' />
            <FormActionBar title={`Editing ${(values as any).firstName}`} onCancel={() => onClose()} onSubmit={() => submitForm()} />
          </FormContainer>
        )}
      </NiceFormik>
    </>
  )
}

const query = graphql`
  query ContactEditFormQuery($slug: String!) {
    infoUpdateContactForm(slug: $slug) {
      formSchema {
        fieldSet {
          name
          type
          required
          title
          description
          format
          enum
          enumTitles
        }
      }
      initialValue {
        slug
        imageSlug
        contactType
        format
        salutation
        firstName
        lastName
        email
        secondaryEmails
        businessPhone
        mobilePhone
        privatePhone
        faxPhone
        company
        position
        country
        state
        place
        postcode
        address
        language
        lexOfficeId
        eori
        vat
        website
        roles
        rateCardSlug
        freeWarehousingDays
        costWarehousingPerDay
        costShipmentDischarge
        vatWarehousing
        vatRevenues
        syncWithEmory
        zollContactNumber
        zollPhone
        atbForCustoms
        blocked
        expenses
      }
    }
  }
`

const mutation = graphql`
  mutation ContactEditMutation($input: infoUpdateContactInput!) {
    infoUpdateContact(input: $input) {
      contact {
        slug
      }
    }
  }
`

ContactEdit.Skeleton = () => (
  <>
    <ContactForm.Skeleton />
    <FormActionBar.Skeleton />
  </>
)

export default ContactEdit
