/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type EditSettingDialog_settingUnion = {
    readonly name?: string;
    readonly forContainers?: boolean;
    readonly forShipments?: boolean;
    readonly code?: string;
    readonly category?: string;
    readonly primaryEmails?: string | null;
    readonly secondaryEmails?: string | null;
    readonly docType?: string;
    readonly title?: string;
    readonly html?: string;
    readonly defaultRecipientRoles?: ReadonlyArray<string>;
    readonly subjectPl?: string;
    readonly subjectDe?: string;
    readonly subjectEn?: string;
    readonly subjectUa?: string;
    readonly bodyPl?: string;
    readonly bodyDe?: string;
    readonly bodyEn?: string;
    readonly bodyUa?: string;
    readonly emailSignature?: string | null;
    readonly messageSignature?: string | null;
    readonly folderType?: string;
    readonly documentName?: string;
    readonly oldDocumentName?: string;
    readonly description?: string;
    readonly label?: string;
    readonly value?: string;
    readonly apply?: boolean;
    readonly emailTemplateName?: string | null;
    readonly emailTemplateSlug?: string | null;
    readonly portHandlingCharge?: number;
    readonly vatChargePercent?: number;
    readonly dutiesRatePercent?: number;
    readonly defaultCleaning?: number | null;
    readonly standardCustomsClearance?: number | null;
    readonly fiscalCustomsClearance?: number | null;
    readonly transitCustomsClearance?: number | null;
    readonly physicalCustomsReview?: number | null;
    readonly forklift?: number | null;
    readonly specialHandling?: number | null;
    readonly twoPackCarDischarge?: number | null;
    readonly threePackCarDischarge?: number | null;
    readonly fourPackCarDischarge?: number | null;
    readonly roroCarDischarge?: number | null;
    readonly motorcycleDischarge?: number | null;
    readonly freeWarehousingDays?: number | null;
    readonly costWarehousing?: number | null;
    readonly xRay?: boolean;
    readonly storage?: boolean;
    readonly cleaning?: boolean;
    readonly " $refType": "EditSettingDialog_settingUnion";
};
export type EditSettingDialog_settingUnion$data = EditSettingDialog_settingUnion;
export type EditSettingDialog_settingUnion$key = {
    readonly " $data"?: EditSettingDialog_settingUnion$data;
    readonly " $fragmentRefs": FragmentRefs<"EditSettingDialog_settingUnion">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "category",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "defaultRecipientRoles",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subjectPl",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subjectDe",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subjectEn",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subjectUa",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bodyPl",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bodyDe",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bodyEn",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bodyUa",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditSettingDialog_settingUnion",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "forContainers",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "forShipments",
          "storageKey": null
        }
      ],
      "type": "RoleSetting",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v1/*: any*/),
        (v0/*: any*/),
        (v2/*: any*/)
      ],
      "type": "PortSetting",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v1/*: any*/),
        (v0/*: any*/)
      ],
      "type": "LanguageSetting",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "primaryEmails",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "secondaryEmails",
          "storageKey": null
        }
      ],
      "type": "ShippingLineSetting",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "docType",
          "storageKey": null
        },
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "html",
          "storageKey": null
        },
        (v2/*: any*/)
      ],
      "type": "DocumentTemplateSetting",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v3/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        (v6/*: any*/),
        (v7/*: any*/),
        (v8/*: any*/),
        (v9/*: any*/),
        (v10/*: any*/),
        (v11/*: any*/),
        (v12/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "emailSignature",
          "storageKey": null
        }
      ],
      "type": "EmailTemplateSetting",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v3/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        (v6/*: any*/),
        (v7/*: any*/),
        (v8/*: any*/),
        (v9/*: any*/),
        (v10/*: any*/),
        (v11/*: any*/),
        (v12/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "messageSignature",
          "storageKey": null
        }
      ],
      "type": "TemplateSetting",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "folderType",
          "storageKey": null
        }
      ],
      "type": "DefaultFolderSetting",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "documentName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "oldDocumentName",
          "storageKey": null
        },
        (v13/*: any*/),
        (v2/*: any*/)
      ],
      "type": "DocumentTypeSetting",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/)
      ],
      "type": "CustomsTypeSetting",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v14/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        },
        (v13/*: any*/)
      ],
      "type": "MiscellaneousSettingSetting",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v14/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "apply",
          "storageKey": null
        },
        (v13/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "emailTemplateName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "emailTemplateSlug",
          "storageKey": null
        }
      ],
      "type": "ConfigSettingSetting",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "portHandlingCharge",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vatChargePercent",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dutiesRatePercent",
          "storageKey": null
        }
      ],
      "type": "ShipmentTypeSetting",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "defaultCleaning",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "standardCustomsClearance",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fiscalCustomsClearance",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "transitCustomsClearance",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "physicalCustomsReview",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "forklift",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "specialHandling",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "twoPackCarDischarge",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "threePackCarDischarge",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fourPackCarDischarge",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "roroCarDischarge",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "motorcycleDischarge",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "freeWarehousingDays",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "costWarehousing",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "xRay",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "storage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cleaning",
          "storageKey": null
        }
      ],
      "type": "RateCardSetting",
      "abstractKey": null
    }
  ],
  "type": "SettingsUnion",
  "abstractKey": "__isSettingsUnion"
};
})();
(node as any).hash = '5c059890203915b084107f35efdccf49';
export default node;
