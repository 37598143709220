/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type MessageEntityTypeEnum = "contacts" | "containers" | "shipments" | "voyages" | "%future added value";
export type infoSendMessageInput = {
    clientMutationId?: string | null;
    subject: string;
    to: Array<string>;
    body: string;
    entityType: MessageEntityTypeEnum;
    entitySlug: string;
    templateSlug?: string | null;
    attachments?: Array<string> | null;
    subjectParams?: Array<string> | null;
    bodyParams?: Array<string> | null;
    userParams?: string | null;
    signature?: string | null;
};
export type SendMessageDialogMutationVariables = {
    input: infoSendMessageInput;
};
export type SendMessageDialogMutationResponse = {
    readonly infoSendMessage: {
        readonly clientMutationId: string | null;
    } | null;
};
export type SendMessageDialogMutation = {
    readonly response: SendMessageDialogMutationResponse;
    readonly variables: SendMessageDialogMutationVariables;
};



/*
mutation SendMessageDialogMutation(
  $input: infoSendMessageInput!
) {
  infoSendMessage(input: $input) {
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "infoSendMessagePayload",
    "kind": "LinkedField",
    "name": "infoSendMessage",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SendMessageDialogMutation",
    "selections": (v1/*: any*/),
    "type": "ApplicationCommand",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SendMessageDialogMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "be9a2d470015a9b4a48d290e21c3a28f",
    "id": null,
    "metadata": {},
    "name": "SendMessageDialogMutation",
    "operationKind": "mutation",
    "text": "mutation SendMessageDialogMutation(\n  $input: infoSendMessageInput!\n) {\n  infoSendMessage(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();
(node as any).hash = '8016c9543d00fcc689ab691639f4cd59';
export default node;
