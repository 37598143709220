import { Avatar, Paper, Tooltip, Typography } from '@material-ui/core'
import { Add, RemoveCircleOutline } from '@material-ui/icons'
// import { Add } from '@material-ui/icons'
import { graphql } from 'babel-plugin-relay/macro'
import React, { useContext, useEffect, useState } from 'react'
import { useFragment } from 'react-relay/hooks'
import { useNavigate } from 'react-router'
import styled from 'styled-components'
import useWorkspace from '../hooks/useWorkspace'
import AddContainerLinkedContacts from '../pages/Container/AddContainerLinkedContacts'
import { CustomsTextLabel } from '../pages/ExternalShipment/ExternalShipmentInfoCard'
import AddShipmentLinkedContacts from '../pages/Shipment/AddShipmentLinkedContacts'
import SessionContext from '../SessionContext'
import { media } from '../theme'
import { LinkedContactsCard_draftContacts$key } from '../__generated__/LinkedContactsCard_draftContacts.graphql'
import { LinkedContactsCard_linkedContactRoles$key } from '../__generated__/LinkedContactsCard_linkedContactRoles.graphql'
import { LinkedContactsCard_linkedContacts$key } from '../__generated__/LinkedContactsCard_linkedContacts.graphql'
import Button from './Button'
import RemoveContactLinkDialog from './RemoveContactLinkDialog'
import SearchContacts from './SearchContacts'
import ShowPageInDrawer from './ShowPageInDrawer'
import pluralize from 'pluralize'
import ExternalContactList from './ExternalContactList'
import { LinkedContactsCard_draftLinkedContactRoles$key } from '../__generated__/LinkedContactsCard_draftLinkedContactRoles.graphql'
import ExternalLiveRequestAddDialog from './ExternalLiveRequestAddDialog'

interface Props {
  entitySlug: string
  entityType: string
  linkedContacts?: LinkedContactsCard_linkedContacts$key
  draftContacts?: LinkedContactsCard_draftContacts$key
  linkedContactRoles?: LinkedContactsCard_linkedContactRoles$key
  draftLinkedContactRoles?: LinkedContactsCard_draftLinkedContactRoles$key
  role?: string
  onHasCustomerChange?: (hasCustomer: boolean) => void
}

const Root = styled(Paper)`
  padding: 16px;
  margin-bottom: 16px;
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`

const ContactCardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;

  ${media.small`
    grid-gap: 8px;
  `}
`

const StyledWorkspacedLink = styled.div`
  border: 1px solid ${(props) => props.theme.customPalette.border.card};
  border-radius: 4px;
  display: flex;
  align-items: center;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    border-color: ${(props) => props.theme.palette.primary.main};
  }
`

export const LinkAvatarContainer = styled.div`
  padding: 8px;
  height: 100%;
  white-space: pre;
`

export const LinkAvatar = styled(Avatar)`
  width: 40px;
  height: 40px;
`

const ContactDetailsContainer = styled.div`
  padding: 8px 8px 8px 0;
  white-space: pre;
  overflow: hidden;
  background-color: ${(props) => props.theme.palette.background.paper};
  flex: 1;
`

const NonOverflowText = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
`

const TitleText = styled(NonOverflowText)`
  line-height: 1;
`

const AddIcon = styled(Add)`
  color: #cacaca;
  cursor: pointer;
  &:hover {
    filter: brightness(0.95);
  }
`

const LinkedContactsCard: React.FC<Props> = ({
  draftContacts: drafts,
  entityType,
  entitySlug,
  linkedContactRoles: contactRolesData,
  linkedContacts: linkedContactsData,
  draftLinkedContactRoles: draftLinkedContactsData,
  role,
  onHasCustomerChange,
}) => {
  const draftContacts = useFragment(draftContactFragment, drafts as LinkedContactsCard_draftContacts$key)
  const hasDraftContact = Array.isArray(draftContacts?.nodes) && draftContacts?.nodes?.length > 0
  const contactRoles = useFragment(contactRolesFragment, contactRolesData as LinkedContactsCard_linkedContactRoles$key)
  const linkedContacts = useFragment(fragment, linkedContactsData as LinkedContactsCard_linkedContacts$key)
  const DraftlinkedContacts = useFragment(
    externalContactLinkfragment,
    draftLinkedContactsData as LinkedContactsCard_draftLinkedContactRoles$key
  )

  const linkedContactRole = contactRoles?.nodes
  const hasLinkedContactRole =
    Array.isArray(linkedContactRole) &&
    linkedContactRole.some((contact: { contactLink: string; role: string }) => contact.role === 'customer' && contact.contactLink)

  const draftLinkedContact = DraftlinkedContacts?.nodes
  const hasDraftLinkedCustomer =
    Array.isArray(draftLinkedContact) &&
    draftLinkedContact.some((contact: { contactLink: string; role: string }) => contact.role === 'customer' && contact.contactLink)

  const linkedContact = linkedContacts?.nodes
  const hasLinkedCustomer =
    Array.isArray(linkedContact) &&
    linkedContact.some((contact: { contactItem: string; role: string }) => contact.role === 'customer' && contact.contactItem)

  const [openContactSlug, setOpenContactSlug] = useState<null | string>(null)
  const [showDraftContact, setShowDraftContact] = useState<boolean>(false)
  const [addContactDialogOpen, setAddContactDialogOpen] = useState(false)
  const [currentRole, setCurrentRole] = useState('')
  const [contactSlug, setContactSlug] = useState('')

  const [workspace] = useWorkspace()
  const navigate = useNavigate()
  const gotoContactsTab = () => navigate(`/${workspace}/${entityType}/${entitySlug}/contacts`)
  const { user } = useContext(SessionContext)
  const [openSearchDrawer, setOpenSearchDrawer] = useState(false)
  const [removeRoleDialog, setRemoveRoleDialog] = useState(false)
  const [openContactListDrawer, setOpenContactListDrawer] = useState(false)
  const [newLiveRequestOpen, setNewLiveRequestOpen] = useState(false)
  const hasCustomer = hasLinkedCustomer || hasDraftLinkedCustomer || hasLinkedContactRole || hasDraftContact

  useEffect(() => {
    if (onHasCustomerChange) {
      onHasCustomerChange(hasCustomer)
    }
  }, [hasCustomer, onHasCustomerChange])

  const closeDrawer = () => {
    setOpenSearchDrawer(false)
    setCurrentRole('')
  }
  const closeDialog = () => {
    setRemoveRoleDialog(false)
    setCurrentRole('')
    setContactSlug('')
  }
  const search = (role: string) => {
    setCurrentRole(role)
    setOpenSearchDrawer(true)
  }
  const remove = (role: string, contactSlug: string) => {
    setCurrentRole(role)
    setContactSlug(contactSlug)
    setRemoveRoleDialog(true)
  }

  const externalSearch = (role: string) => {
    setCurrentRole(role)
    setOpenContactListDrawer(true)
  }
  return (
    <>
      {user?.contact?.contactType == 'internal' && (
        <SearchContacts
          entityType={`${pluralize(entityType)}`}
          entitySlug={entitySlug}
          open={openSearchDrawer}
          onClose={closeDrawer}
          role={currentRole}
        />
      )}
      {removeRoleDialog && (
        <RemoveContactLinkDialog
          open={removeRoleDialog}
          onClose={closeDialog}
          entityType={`${pluralize(entityType)}`}
          entitySlug={entitySlug}
          role={currentRole}
          contactSlug={contactSlug}
          title={currentRole}
        />
      )}

      {entityType === 'shipment' && (
        <AddShipmentLinkedContacts slug={entitySlug} open={addContactDialogOpen} onClose={() => setAddContactDialogOpen(false)} />
      )}
      {entityType === 'container' && (
        <AddContainerLinkedContacts slug={entitySlug} open={addContactDialogOpen} onClose={() => setAddContactDialogOpen(false)} />
      )}
      <Root variant='outlined'>
        <TitleContainer>
          <Typography variant='h6'>Contacts</Typography>
          <span>
            {user?.contact?.contactType == 'internal' && <Button onClick={() => gotoContactsTab()}>Edit</Button>}
            <Button onClick={() => gotoContactsTab()}>See All</Button>
          </span>
        </TitleContainer>

        <ContactCardsContainer>
          {linkedContacts &&
            linkedContacts.nodes?.map(
              (link) =>
                link && (
                  <>
                    <StyledWorkspacedLink onClick={() => setOpenContactSlug(link.contactItem.slug)}>
                      <LinkAvatarContainer>
                        {link.contactItem.image && <LinkAvatar alt={link.contactItem.image.alt || ''} src={link.contactItem.image.url || ''} />}
                      </LinkAvatarContainer>
                      <ContactDetailsContainer>
                        <TitleText variant='body1'>{link.displayName}</TitleText>
                        <NonOverflowText variant='caption' color='textSecondary'>
                          {link.role}
                        </NonOverflowText>
                      </ContactDetailsContainer>
                    </StyledWorkspacedLink>
                  </>
                )
            )}
        </ContactCardsContainer>
        <div>
          <ContactCardsContainer>
            {contactRoles &&
              contactRoles.nodes
                ?.filter((link) => !role || link?.role === role)
                .map(
                  (link) =>
                    link && (
                      <>
                        {link.contactLink ? (
                          <StyledWorkspacedLink
                            onClick={() => {
                              setOpenContactSlug(link?.contactLink?.contactItem.slug as string)
                            }}
                          >
                            <LinkAvatarContainer>
                              {link.contactLink.contactItem.image && (
                                <LinkAvatar
                                  alt={link.contactLink.contactItem.image.alt || ''}
                                  src={link.contactLink.contactItem.image.url || ''}
                                />
                              )}
                            </LinkAvatarContainer>
                            <ContactDetailsContainer>
                              <TitleText variant='body1'>{link.contactLink.displayName}</TitleText>
                              <NonOverflowText variant='caption' color='textSecondary'>
                                {link.role}
                              </NonOverflowText>
                            </ContactDetailsContainer>
                            <Tooltip title='Remove' placement='top' interactive>
                              <RemoveCircleOutline
                                fontSize='small'
                                onClick={(event) => {
                                  event.stopPropagation()
                                  remove(link.role, link?.contactLink?.contactItem.slug as string)
                                }}
                              />
                            </Tooltip>
                          </StyledWorkspacedLink>
                        ) : (
                          <>
                            <Button
                              onClick={() => {
                                search(link.role)
                              }}
                            >
                              Add {link.role}
                            </Button>
                          </>
                        )}
                      </>
                    )
                )}
          </ContactCardsContainer>
        </div>
        {user?.contact?.contactType == 'external' && (
          <div>
            <CustomsTextLabel>Draft Contact Link</CustomsTextLabel>
            <ContactCardsContainer>
              {DraftlinkedContacts &&
                DraftlinkedContacts.nodes?.map(
                  (link) =>
                    link && (
                      <>
                        {link.contactLink ? (
                          <StyledWorkspacedLink
                            onClick={() => {
                              setOpenContactSlug(link?.contactLink?.contactItem.slug as string)
                            }}
                          >
                            <LinkAvatarContainer>
                              {link.contactLink.contactItem.image && (
                                <LinkAvatar
                                  alt={link.contactLink.contactItem.image.alt || ''}
                                  src={link.contactLink.contactItem.image.url || ''}
                                />
                              )}
                            </LinkAvatarContainer>
                            <ContactDetailsContainer>
                              <TitleText variant='body1'>{link.contactLink.displayName}</TitleText>
                              <NonOverflowText variant='caption' color='textSecondary'>
                                {link.role}
                              </NonOverflowText>
                              {link.ticketStatus && <p>{link.ticketStatus}</p>}
                            </ContactDetailsContainer>
                          </StyledWorkspacedLink>
                        ) : (
                          <>
                            <Button
                              onClick={() => {
                                externalSearch(link.role)
                              }}
                            >
                              Add {link.role}
                            </Button>
                          </>
                        )}
                      </>
                    )
                )}
            </ContactCardsContainer>
          </div>
        )}
        <div>
          {(user?.contact?.contactType == 'external' || (draftContacts && (draftContacts.nodes?.length as number)) > 0) && (
            <CustomsTextLabel>Draft Contacts</CustomsTextLabel>
          )}
          <ContactCardsContainer>
            {draftContacts &&
              draftContacts.nodes?.map(
                (link) =>
                  link && (
                    <>
                      <StyledWorkspacedLink
                        onClick={() => {
                          setShowDraftContact(true)
                          setOpenContactSlug(link.slug)
                        }}
                      >
                        <LinkAvatarContainer>
                          {link.image && <LinkAvatar alt={link.image.alt || ''} src={link.image.url || ''} />}
                        </LinkAvatarContainer>
                        <ContactDetailsContainer>
                          <TitleText variant='body1'>{link.name}</TitleText>
                          <NonOverflowText variant='caption' color='textSecondary'>
                            {link.displayRoles}
                          </NonOverflowText>
                        </ContactDetailsContainer>
                      </StyledWorkspacedLink>
                    </>
                  )
              )}
            {user?.contact?.contactType == 'external' && (
              <Button onClick={() => setNewLiveRequestOpen(true)}>
                <AddIcon />
              </Button>
            )}
          </ContactCardsContainer>
        </div>
        <ShowPageInDrawer
          entityType='contact'
          entitySlug={openContactSlug || ''}
          open={!!openContactSlug}
          onClose={() => {
            setShowDraftContact(false)
            setOpenContactSlug(null)
          }}
          showDraftContact={showDraftContact}
        />
        {user?.contact?.contactType == 'external' && (
          <ExternalContactList
            entityType={`${pluralize(entityType)}`}
            entitySlug={entitySlug}
            open={openContactListDrawer}
            onClose={() => {
              setOpenContactListDrawer(false)
              setCurrentRole('')
            }}
            role={currentRole}
          />
        )}
        <ExternalLiveRequestAddDialog
          open={newLiveRequestOpen}
          title='Raise new ticket'
          onClose={() => setNewLiveRequestOpen(false)}
          onEdit={() => setNewLiveRequestOpen(false)}
          entitySlug={entitySlug}
          entityType={entityType}
        />
      </Root>
    </>
  )
}

const fragment = graphql`
  fragment LinkedContactsCard_linkedContacts on ContactLinkConnection {
    nodes {
      role
      displayRole
      displayName
      contactItem {
        slug
        title
        image {
          url
          alt
        }
      }
    }
  }
`

const contactRolesFragment = graphql`
  fragment LinkedContactsCard_linkedContactRoles on ContactLinkRoleConnection {
    nodes {
      role
      contactLink {
        displayRole
        displayName
        contactItem {
          slug
          title
          image {
            url
            alt
          }
        }
      }
    }
  }
`

const draftContactFragment = graphql`
  fragment LinkedContactsCard_draftContacts on DraftContactConnection {
    nodes {
      slug
      name
      displayRoles
      image {
        url
        alt
      }
    }
  }
`

const externalContactLinkfragment = graphql`
  fragment LinkedContactsCard_draftLinkedContactRoles on DraftContactLinkRoleConnection {
    nodes {
      role
      ticketStatus
      contactLink {
        displayRole
        displayName
        contactItem {
          slug
          title
          image {
            url
            alt
          }
        }
      }
    }
  }
`
export default LinkedContactsCard
