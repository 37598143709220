import { Paper, Typography } from '@material-ui/core'
import { graphql } from 'babel-plugin-relay/macro'
import React, { useState } from 'react'
import { useFragment } from 'react-relay/hooks'
import styled from 'styled-components'
import { MessagesCard_messages$key } from '../../__generated__/MessagesCard_messages.graphql'
import Button from '../Button'
import SendMessageDialog from './SendMessageDialog'
import { MessageEntityEnum } from './types'
import MessageItem from './MessageItem'

interface Props {
  entitySlug: string
  entityType: MessageEntityEnum
  messages: MessagesCard_messages$key
}

const Root = styled(Paper)`
  padding-top: 16px;
  margin-bottom: 16px;
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px;
  margin-bottom: 8px;
`

const NoDataText = styled(Typography)`
  color: ${(props) => props.theme.customPalette.text.noData};
  text-align: center;
  padding: 16px;
  font-weight: 400;
`

const MessageCard: React.FC<Props> = ({ entitySlug, entityType, messages: data }) => {
  const [messagelNewOpen, setMessageNewOpen] = useState(false)
  const messagesConnection = useFragment(fragment, data)

  return (
    <Root variant='outlined'>
      <TitleContainer>
        <Typography variant='h6'>Messages</Typography>
        {entityType !== 'voyages' && <Button onClick={() => setMessageNewOpen(true)}>New Message</Button>}
      </TitleContainer>
      {messagesConnection.nodes?.length === 0 && <NoDataText variant='h6'>No Messages</NoDataText>}
      {messagesConnection.nodes?.map((message) => message && <MessageItem key={message.slug} message={message} />)}
      <SendMessageDialog open={messagelNewOpen} onClose={() => setMessageNewOpen(false)} entityType={entityType} entitySlug={entitySlug} />
    </Root>
  )
}

const fragment = graphql`
  fragment MessagesCard_messages on MessageConnection {
    nodes {
      slug
      ...MessageItem_message
    }
  }
`

export default MessageCard
